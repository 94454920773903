import React, { createRef } from 'react';
import { images } from '../../../../constants/Images';
import { ISearchBrand, ISearchModel, ISearchSize, ISearchGetTires } from '../../../../models/ISearchState';
import config from '../../../../constants/config';
import { Modal, Input } from '../../../../components';
import { validation, i18n } from '../../../../services';
import './SizeCard.css'
import { withTranslation } from 'react-i18next';
interface IProps {
  items: ISearchSize
  onProceed: (param: any) => void;
}

interface IState {
  toggelModal: boolean
  formData: { fields: { [key in string]: any }; required: string[] }
  validation: any;
  inputType: string[];
}



class SizeCard extends React.PureComponent<IProps, IState> {

  state = {
    toggelModal: false,
    formData: {
      fields: {
        f_size_rear: this.props.items.f_rear_size,
        s_size_rear: this.props.items.s_rear_size,
        t_size_rear: this.props.items.t_rear_size,
        f_size: this.props.items.f_size,
        s_size: this.props.items.s_size,
        t_size: this.props.items.t_size,
        quantity: '',
        quantity_rear: ''
      },
      required: []
    },
    validation: {},
    inputType: []
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
    const { items } = nextProps
    const { formData } = prevState
    // console.log('componentDidMount', items);

    let inputArr: string[] = ['quantity']
    // @ts-ignore-start
    if ((items.f_size !== '0' && items.s_size !== '0' && items.t_size !== '0') && (items.f_rear_size !== '0' && items.s_rear_size !== '0' && items.t_rear_size !== '0')) {
      inputArr = ['quantity', 'quantity_rear']
      // @ts-ignore-start
    } else if (items.f_rear_size !== '0' && items.s_rear_size !== '0' && items.t_rear_size !== '0') {
      inputArr = ['quantity_rear']
    }
    // console.log('inputArr', inputArr);

    // this.setState({formData: { ...formData, required: inputArr.length > 1 ? ['quantity'] : inputArr }, inputType: inputArr})
    return {
      formData: { ...formData, required: inputArr.length === 1 ? ['quantity'] : inputArr },
      inputType: inputArr
    };
  }


  onToggel = () => {
    const { toggelModal } = this.state;
    this.setState({ toggelModal: !toggelModal, validation: {} })
  }

  handleChange = (e: any, field: string) => {
    var arabics = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
    var engs = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const { formData } = this.state;
    let value = e.target.value.replace(/^0+/, '')
    if (value.length > 1) {
      value = value.slice(0, 1)
    }
    this.setState({ formData: { ...formData, fields: { ...formData.fields, [field]: value } } })
  }

  onSubmit = () => {
    const { formData } = this.state;
    const { onProceed, items } = this.props;
    // console.log('formData', formData);

    const validate = validation.isValidate(formData)
    this.setState({ validation: validate })
    // console.log('items', items);
    // console.log('formData', formData);
    if (!Object.keys(validate).length) {
      const params = {
        f_size_rear: this.props.items.f_rear_size,
        s_size_rear: this.props.items.s_rear_size,
        t_size_rear: this.props.items.t_rear_size,
        f_size: this.props.items.f_size,
        s_size: this.props.items.s_size,
        t_size: this.props.items.t_size
      }
      onProceed({ ...formData.fields, ...params, rear_size_tyre: formData.fields.quantity_rear ? 1 : 0 })
      //  delete params.message
      // contactUs(params);
    }
  }

  renderInput = () => {
    const { formData, inputType } = this.state
    // console.log('inputType', inputType);

    return inputType.map((raw: string, index: number) => {
      return (
        <Input
          type={'text'}
          // @ts-ignore-start
          value={formData.fields[`${raw}`]}
          // @ts-ignore-end
          name="quantity"
          onChange={(e: any) => this.handleChange(e, raw)}
          placeholder={i18n.t(raw === 'quantity' ? 'quantity' : 'search.quantity.input.placholder.rear')}
          error={validation.renderError(raw, this.state.validation)}
          maxLength={1}
        />
      )
    })

  }

  render() {
    const { items } = this.props;
    const { toggelModal, formData } = this.state;
    // console.log('items', items);
    return (
      <>
        <div className="card-top col-lg-6 col-md-6 col-sm-6 col-12 px-3">


          {items.f_rear_size > 0 ? (
            <div className="card-box d-flex" >
              <div className="box-left mr-auto">
                <span>{i18n.t(`search.vehicle.modal.label.front_size`)} :</span>
                <h3>{items.f_size}/{items.s_size}R-{items.t_size}</h3>
                <span>{i18n.t(`search.vehicle.modal.label.rear_size`)} :</span>
                <h3>{items.f_rear_size}/{items.s_rear_size}R-{items.t_rear_size}</h3>
              </div>
              <div className="box-right">
                <img src={images.right} />
                <span>{items.t_size}</span>
                <p>{i18n.t(`search.vehicle.modal.label.rim_size`)}</p>
              </div>
              <div className="box-right">
                <img src={images.right} />
                <span>{items.t_rear_size}</span>
                <p>{i18n.t(`search.vehicle.modal.label.rim_size`)}</p>
              </div>
            </div>
          ) : (
              <div className="card-box d-flex" >
                <div className="box-left mr-auto">
                  <span>Tire Size :</span>
                  <h3>{items.f_size}/{items.s_size}R-{items.t_size}</h3>
                </div>
                <div className="box-right">
                  <img src={images.right} />
                  <span>{items.t_size}</span>
                  <p>{i18n.t(`search.vehicle.modal.label.tire_size`)}</p>
                </div>
              </div>
            )}


          <div className="select-btn">
            <a href="javascript:void(0);" data-toggle="modal" data-target="#Proceed" onClick={this.onToggel}>{i18n.t(`search.vehicle.modal.card.button.select`)}</a>
          </div>
        </div>
        <Modal
          onToggel={this.onToggel}
          show={toggelModal}
        >

          <div className="modal-header title-modal">
            <h4 className="modal-title">{i18n.t(`search.vehicle.modal.quantity.title`)}</h4>
          </div>

          <div className="modal-body quantity">
            <form>
              {this.renderInput()}
            </form>
          </div>

          <div className="modal-footer proceed">
            <button type="button" className="footer-btn searchbyvehicle" onClick={this.onSubmit}>{i18n.t(`search.vehicle.modal.quantity.button.proceed`)}<img src={images.rightIcon} /></button>
          </div>
        </Modal>
      </>
    )
  }
}

export default withTranslation()(SizeCard)