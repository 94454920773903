import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import { IHomeknowyourtireData } from '../../../models/IHomeState';
import { images } from '../../../constants/Images';
import { Container } from '../../../components/Base';
import { withRouter } from 'react-router-dom';
import { Filter } from '../../../utils';
import config from '../../../constants/config';
import { connect } from 'react-redux';
import IRootState, { IHomeState} from '../../../models';
import { home as homeActions, core as coreActions, user as userActions } from '../../../actions';
import { Helmet } from "react-helmet";

interface IProps {
   history: any;
   data: any;
   items: IHomeknowyourtireData
   location: {
      pathname: any; state: IHomeknowyourtireData 
}
   getData: ({}) => void;
   home: IHomeState
}

interface IState {
   blog_data?: IHomeknowyourtireData; // Allow undefined
}

class BlogDetailPage extends React.Component<IProps, IState> {

   state: IState =  {
		blog_data: undefined, // Initially undefined
	}

   componentDidMount() {
      const { getData, location, history } = this.props;
      const slug = location.pathname.split("/").pop();
    
      if (!slug || slug === 'blog-detail') {
        history.push("/"); // Redirect to home if slug is missing
      }
    
      if (location.state) {
        // Set blog_data from location.state if available
        this.setState({ blog_data: location.state });
      } else {
        // 🔄 Fetch data if state is missing (e.g., on page refresh)
        getData({});
      }
    }

    componentDidUpdate(prevProps: IProps) {
      const { home, location } = this.props;
    
      if (prevProps.home.data.knowyourtireData !== home.data.knowyourtireData) {
        const blogData = home.data.knowyourtireData;
        const slug = location.pathname.split("/").pop();
        const foundTire = blogData.find((tire) => tire.slug_english === slug);
        if (foundTire) {
          this.setState({ blog_data: foundTire });
        }
      }
    }

    render() {
      const { blog_data } = this.state;
       
      return (
         <>

         <Helmet>
          <title>{blog_data ? Filter.translate(blog_data.page_title, blog_data.title_arebic) : ""}</title>
          <meta name="description" content={blog_data ? Filter.translate(blog_data.meta_description_en, blog_data.meta_description_ar) : ""} />
          <meta name="keywords" content={blog_data ? Filter.translate(blog_data.meta_keyword_en, blog_data.meta_keyword_ar) : ""} />
         </Helmet>

         <Container>
      <section className="inner-page-banner center-text-banner bg-size-cover" style={{ background: ` #fcc223` }}>
        <div className="tire-mark"><img src={images.innerTireMark} alt=""/></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="list-heading text-center">
                <h3>{blog_data ? Filter.translate(blog_data.page_title, blog_data.title_arebic) : ''}</h3>
              </div>
            </div>         
          </div>
        </div>
      </section>

      <section className="privacy-policy blogdetailspage">
        <div className="container px-5">
          <img className="blogfullimg" src={blog_data ? `${config.imageBaseUrl}${blog_data.path}` : ''} />
          <br/>

          <div className="row justify-content-center">
            <div className="col-lg-12 mb-lg-5">
              <div className="privacy-content">
                {blog_data ? ReactHtmlParser(Filter.translate(blog_data.content, blog_data.content_arebic)) : ''}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Container>
    </>
      )
    }
}

const mapStateToProps = (state: IRootState) => ({
   home: state.home,
 });
 
 const mapDispatchToProps = {
   getData: homeActions.getData,
 }

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(BlogDetailPage)) )