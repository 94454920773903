import React from 'react';

import { images } from '../../../constants/Images';
import IRootState, { ISearchState, ICoreState } from '../../../models';
import { connect } from 'react-redux';
import { Header, BrandCard, Years, SizeCard } from '../Components';
import { search as searcActions } from '../../../actions'
import { PATH } from '../../../navigation/NavigationPath';
import { withRouter } from "react-router";
import { ISearchGetYear, ISearchGetModel, ISearchBrand, ISearchGetSize, ISearchGetTires } from '../../../models/ISearchState';
import { Modal } from '../../../components';
import { i18n } from '../../../services';
import { Loader } from '../../../components/Base';
import { core as coreActions } from '../../../actions'
import { Helmet } from "react-helmet";
import { Filter } from '../../../utils';

type pageType = 'make' | 'year' | 'model' | 'size'

interface IProps {
  getBrand: () => void;
  search: ISearchState;
  history: any;
  getYear: (param: ISearchGetYear) => void;
  match: { params: { id: string } }
  location: { pathname: string, state:{ id: string, path: pageType, values: {  selectedYear?: number, selectedModelName?: string, selectedBrandName: string, selectedBrand: number, selectedModel?: number } } }
  getModel: (param: ISearchGetModel) => void;
  getSize: (param: ISearchGetSize) => void;
  getTires: (param: ISearchGetTires) => void;
  core: ICoreState;
  setVehicleState: (param: IBrandState | {}) => void;
  getMetaData: ({  }) => void;
}

export interface IBrandState {
  selectedBrand: number
  selectedYear: number;
  selectedModel: number;
  selectedModelName: string;
  selectedBrandName: string
  headerMenu: { name: string, value: any, id: number }[];
  activeHeaderMenu: number;
  pageType: pageType;
}

class BrandPage extends React.Component<IProps, IBrandState> {

    state: IBrandState =  {
      selectedBrand: this.props.location.state && this.props.location.state.values && this.props.location.state.values.selectedBrand ? this.props.location.state.values.selectedBrand : 0,
      selectedYear: this.props.location.state && this.props.location.state.values && this.props.location.state.values.selectedYear ? this.props.location.state.values.selectedYear : 0,
      selectedModel: 0,
      selectedBrandName: this.props.location.state && this.props.location.state.values && this.props.location.state.values.selectedBrandName ? this.props.location.state.values.selectedBrandName : '',
      selectedModelName: this.props.location.state && this.props.location.state.values && this.props.location.state.values.selectedModelName ? this.props.location.state.values.selectedModelName : '',
      headerMenu: [
        { name: `search.vehicle.header.make`, value: this.props.location.state && this.props.location.state.values && this.props.location.state.values.selectedBrandName ? this.props.location.state.values.selectedBrandName : '', id:1},
        { name: `search.vehicle.header.year`, value: this.props.location.state && this.props.location.state.values && this.props.location.state.values.selectedYear ? this.props.location.state.values.selectedYear : '', id:2},
        { name: `search.vehicle.header.modal`, value: this.props.location.state && this.props.location.state.values && this.props.location.state.values.selectedModelName ? this.props.location.state.values.selectedModelName : '', id: 3},
      ],
      activeHeaderMenu: this.props.location.state && this.props.location.state.path ? ['make', 'year', 'model'].indexOf(this.props.location.state.path)+1 : 1,
      pageType: this.props.location.state && this.props.location.state.path ? this.props.location.state.path : 'make',
    }

    componentDidMount = () => {
      const { getBrand,  } = this.props;
      getBrand();
      window.onpopstate = () => {
        const { location,  } = this.props;
        const { headerMenu } = this.state;
        const activeHeader = ['make', 'year', 'model']
       this.setState({ 
         pageType: location.state && location.state.path ? location.state.path : 'make', 
         activeHeaderMenu : location.state && location.state.path ? activeHeader.indexOf(location.state.path)+1 : 1 
        }, () => {
          
          if (this.state.pageType === 'make') {
            this.setState({ selectedYear: 0, selectedModel: 0 })
          } else if (this.state.pageType === 'year') {
            this.setState({ selectedModel: 0 })
          }
          if (this.state.pageType !== 'model' && this.state.pageType !== 'size') {
            this.setState({
              headerMenu: [
                { name: `search.vehicle.header.make`, value: headerMenu[0].value, id:1},
                { name: `search.vehicle.header.year`, value: this.state.pageType === 'year' ? headerMenu[1].value : '', id:2},
                { name: `search.vehicle.header.modal`, value: '', id: 3},
              ],
            })
          }
          
          
        })
      };

      this.props.getMetaData({
        slug: 'search_by_vehicle'
      })
    }

  

    componentWillUnmount() {
      window.onpopstate = () => {}
      this.props.setVehicleState(this.state);
    }

    renderItem = () => {
      const { search, location } = this.props;
      const { selectedBrand, selectedYear, selectedModel, pageType } = this.state;
      const type = location.state && location.state.path ? location.state.path : 'make'
      
      const DATA: { [key in any]: any } = {
        make: search.brand,
        year: search.years,
        model: search.model,
        size: search.size,
      }
console.log('pageType', pageType);
      if (!DATA[type].length) {
        return <div style={{ margin: 'auto' }}><Loader/></div>
      }
      if (pageType === 'year' || pageType === 'model') {
        return (
          <div className="col-lg-12">
          <ul className="year-filter">
            {
              DATA[type].map((row: any, index: number) => {
                return <Years  
                  items={row} key={index} 
                  onClick={this.onSelect} 
                  selectedYear={selectedYear}
                  selectedModel={selectedModel}
                />
              })
            }
            </ul>
            </div>
        )

      }

      if (pageType === 'size') {
        return DATA[type].map((row: any, index: number) => {
          return <SizeCard  items={row} key={index} onProceed={this.onProceed}/>
        })
      }

      return DATA[type].map((row: any, index: number) => {
        return <BrandCard  items={row} key={index} onClick={this.onSelectBrand} selectedBrand={selectedBrand}/>
      })
    }

    onProceed = (param: ISearchGetTires) => {
      const { getTires, history, core } = this.props;
      // getTires({...param, city_id: core.selectedCity.id});

      console.log('onProceed', param);
      param.city_id = core.selectedCity.id;
      const filteredParams = Object.fromEntries(
        Object.entries(param).filter(([key]) => !["promotions", "promotions_rear"].includes(key))
      );
      // Convert to query string
      const queryString = new URLSearchParams(filteredParams).toString();
      // console.log('testinggggggggggg', queryString);
      history.push(PATH.SEARCH_TIRES_LIST+'?'+queryString, {...param, city_id: core.selectedCity.id})
    }


    onSelect = ({ id, type, name }: { id: any, type: 'make' | 'year' | 'model' | 'size', name: string }) => {
      const { getModel, history, getSize, location } = this.props;
      const { selectedBrand, selectedYear, headerMenu } = this.state;
      const header = headerMenu
      
      const setData: 'selectedYear' | 'selectedModel' =  type === 'year' ? 'selectedYear' : 'selectedModel'
      
      // @ts-ignore-start
      this.setState({[setData]: id, pageType: type === 'year' ? 'model' : 'size'});
      // @ts-ignore-end
      let activeHeaderMenu = 0
      if (type === 'year') {
        getModel({ brand_id: selectedBrand, year: id })
        history.push(`${PATH.SEARCH_BY_BRAND}`, { id, path: 'model', values: { ...location.state.values, selectedYear: id } })
        header.splice(1, 1, { ...header[1], value: id })
        activeHeaderMenu = header[2].id
      } else {
        // @ts-ignore-start
        getSize({ brand_id: selectedBrand, year: selectedYear, model_id: id,  city_id: 9})
        history.push(`${PATH.SEARCH_BY_BRAND}`, { id, path: 'size', values: { ...location.state.values, selectedModelName: name, selectedModel: id }  })
        this.setState({ selectedModelName: name })
        header.splice(2, 1, { ...header[2], value: name})
      }
      this.setState({headerMenu: header, activeHeaderMenu})
    }

    onSelectBrand = (row: ISearchBrand) => {
      const { headerMenu } = this.state;
      const header = headerMenu
      header.splice(0, 1, { ...header[0], value: row.brand })
      this.setState({ 
        selectedBrand: row.id, 
        selectedBrandName: row.brand,
        headerMenu: header,
        activeHeaderMenu: header[1].id,
        pageType: 'year'
      })
      this.props.getYear({ brand_id: row.id })
      this.props.history.push(`${PATH.SEARCH_BY_BRAND}`, { id: row.id, path: 'year', values: { selectedBrandName: row.brand, selectedBrand: row.id } })
    }

    goBack = () => {
      this.props.history.goBack()
    }

    static getDerivedStateFromProps(props: IProps, state: IBrandState) {
      console.log('props', props);
    
      if (Object.keys(props.search.vehiclePage).length) {
        props.setVehicleState({});
        // return props.search.vehiclePage
      }
      return {
          ...state,
          activeHeaderMenu: props.location.state && props.location.state.path ? ['make', 'year', 'model'].indexOf(props.location.state.path)+1 : 1,
          pageType: props.location.state && props.location.state.path ? props.location.state.path : 'make',
      }
    }

    onClickMenu = (id:  number) => {
      console.log('id', id);
      const { headerMenu, pageType } = this.state;
      const pageTypeArr: pageType[] = ['make', 'year', 'model', 'size']
      const { history } = this.props
      const pageIndex = -pageTypeArr.indexOf(pageType)+(id-1)
      console.log('pageIndex', pageIndex);
      
      if (id !== pageTypeArr.indexOf(pageType)+1) {
        if (id > pageTypeArr.indexOf(pageType)+1) {
          if (headerMenu[id-2].value) {
            history.go(pageIndex)
          }
        } else {
          history.go(pageIndex)
        }
        
        // history.go(pageIndex)
        if (id === 1) {
          this.setState({ selectedYear: 0, selectedModel: 0 })
        } else if (id === 2) {
          this.setState({ selectedModel: 0 })
        } 
        
        
        if (id !== 3) {
          this.setState({
            headerMenu: [
              { name: `search.vehicle.header.make`, value: headerMenu[0].value, id:1},
              { name: `search.vehicle.header.year`, value: id === 2 ? headerMenu[1].value : '', id:2},
              { name: `search.vehicle.header.modal`, value: '', id: 3},
            ],
          })
        }
      }


       
    }
  

    render() {
      const { location, core } = this.props
      const type = location.state && location.state.path ? location.state.path : 'make'
      const { selectedBrandName, selectedModelName,  selectedYear, headerMenu, activeHeaderMenu} = this.state;
      console.log('this.state', this.state);
      console.log('this.props.location', this.props.location);
      
      return (
        <>

        <Helmet>
          <title>{core.metaData ? Filter.translate(core.metaData.title_en, core.metaData.title_arebic) : ""}</title>
          <meta name="keywords" content={core.metaData ? Filter.translate(core.metaData.meta_keyword_en, core.metaData.meta_keyword_ar) : ""} />
          <meta name="description" content={core.metaData ? Filter.translate(core.metaData.meta_description_en, core.metaData.meta_description_ar) : ""} />
        </Helmet>

         <div className="toolbar-enabled toolbar-marvelapp">
           <Header 
              type={type}
              menu={headerMenu}
              active={activeHeaderMenu}
              title={i18n.t(`search.vehicle.header.size.title`)}
              goBack={this.goBack} 
              onClickMenu={this.onClickMenu}
           />
            <section className={type === 'make' ? 'brands-marvelapp': 'brands-estb'}>
              <div className="container">
                <div className="row pb-2 pb-sm-0 pb-md-3 brands">
                {this.renderItem()}
                </div>  
              </div>
            </section>
         </div>
         </>
      )
    }
}


  const mapStateToProps = (state: IRootState) => ({
    search: state.search,
    core: state.core
  });
 
  const mapDispatchToProps = {
    getBrand: searcActions.getBrand,
    getYear: searcActions.getYear,
    getModel: searcActions.getModel,
    getSize: searcActions.getSize,
    getTires: searcActions.getTires,
    setVehicleState: searcActions.setVehicleState,
    getMetaData: coreActions.getMetaData
  }
 
 
 
 export default withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(BrandPage)) 