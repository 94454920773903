import React from 'react';
import IRootState, { ICoreState } from '../../../models';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { core as coreActions } from '../../../actions'
import { Helmet } from "react-helmet";
import { Filter } from '../../../utils';

interface IProps {
   core: ICoreState;
   getMetaData: (params: { slug: string }) => void;
}

class MetaDataCls extends React.Component<IProps> {

   componentDidMount = () => {
      const { getMetaData } = this.props;
      const slug = localStorage.getItem('metadataslug');
      if (slug) {
         getMetaData({ slug });
      }
   }

render () {
   const { core } = this.props
      return (
         <>
         <Helmet>
            <title>{core.metaData ? Filter.translate(core.metaData.title_en, core.metaData.title_arebic) : ""}</title>
            <meta name="keywords" content={core.metaData ? Filter.translate(core.metaData.meta_keyword_en, core.metaData.meta_keyword_ar) : ""} />
            <meta name="description" content={core.metaData ? Filter.translate(core.metaData.meta_description_en, core.metaData.meta_description_ar) : ""} />
        </Helmet>
        </>
      )
    }
}

const mapStateToProps = (state: IRootState) => ({
   core: state.core
 });

 const mapDispatchToProps = {
   getMetaData: coreActions.getMetaData
 }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(MetaDataCls)) 