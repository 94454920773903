import { put, takeEvery, call, delay } from 'redux-saga/effects'
import { cart as cartActions, core as coreActions} from '../actions'
import { api } from '../services';
import { IActionAddToCart, IActionGetCart, IActionCartCancelOrder, IActionCartGetOrderDetail, IActionTamaraPreOrder, IActionGetTamaraCheckout, IActionGetTamaraConfirmation, IActionGetPaymentConfirmation, IActionGetTabbyCheckout, IActionGetTabbyPayment, IActionCouponcodeApply } from '../actions/cartActions';

export function* addToCartSaga(action: IActionAddToCart) {
  try {
    const data  = yield call(api.cart.addToCart, action.payload);
    console.log('data', data);
    yield put(cartActions.addToCartSuccess(data));
  } catch(e) {
    
  }

}

export function* tamaraPreOrder(action: IActionTamaraPreOrder) {
  try {
    const {data}  = yield call(api.cart.tamaraPreOrder, action.payload);
    yield put(cartActions.setTamaraPreOrder(data));
  } catch(e) { }
}

export function* couponCodeApply(action: IActionCouponcodeApply) {
  try {
    const {data, statusCode, message}  = yield call(api.cart.couponCodeApply, action.payload);
    let record_data = data;
    if(statusCode === 200){
      record_data = {
        ...record_data,
        statuscode:statusCode,
        statusmsg:message
      }
    } else {
      record_data = {
        id:null,
        name:'',
        type:'',
        discount:'',
        statuscode:statusCode,
        statusmsg:message
      }
    }

    yield put(cartActions.setCouponCodeApply(record_data));
  } catch(e) { }
}

export function* tamaraCheckoutSaga(action: IActionGetTamaraCheckout) {
  try {
    const {data, message, statusCode}  = yield call(api.cart.tamaraCheckout, action.payload);
    console.log('tppppppppppp==>',statusCode);
    let record_data = data;
    if(statusCode != 200){
      record_data = {
        order_id:'',
        checkout_id:'',
        checkout_url:'',
        status:message
      }
    }
    yield put(cartActions.setTamaraCheckout(record_data));
  } catch(e) { }
}

export function* tabbyCheckoutSaga(action: IActionGetTabbyCheckout) {
  try {
    const {data, message, statusCode}  = yield call(api.cart.tabbyCheckout, action.payload);
    const tabbyCheckoutObj = {
      data:data,
      message:message,
      status_code:statusCode,
    }
    yield put(cartActions.setTabbyCheckout(tabbyCheckoutObj));
  } catch(e) { }
}

export function* tabbyPaymentSaga(action: IActionGetTabbyPayment) {
  try {
    const {data, message, statusCode}  = yield call(api.cart.tabbyPayment, action.payload);
    const tabbyCheckoutObj = {
      id:data?.id,
      order_number:data?.order_number,
      reference_number:data?.order_reference_id,
      message:message,
      status_code:statusCode,


    }
    yield put(cartActions.setTabbyPayment(tabbyCheckoutObj));
  } catch(e) { }
}

export function* tamaraConfirmationSaga(action: IActionGetTamaraConfirmation) {
  try {
    const {data, message, statusCode}  = yield call(api.cart.tamaraConfirmation, action.payload);
    const tamaraConfirmObj = {
      id:data?.id,
      order_number:data?.order_number,
      reference_number:data?.order_reference_id,
      message:message,
      status_code:statusCode,
    }
    yield put(cartActions.setTamaraConfirmation(tamaraConfirmObj));
  } catch(e) { }
}

export function* paymentConfirmationSaga(action: IActionGetPaymentConfirmation) {
  try {
    const {data, message, statusCode}  = yield call(api.cart.paymentConfirmation, action.payload);
    const tamaraConfirmObj = {
      message:message,
      status_code:statusCode,
    }
    yield put(cartActions.setPaymentConfirmation(tamaraConfirmObj));
  } catch(e) { }
}

export function* getCart(action: IActionGetCart) {
  try {
    const {orderData = []}  = yield call(api.cart.getCart, action.payload);
    yield put(cartActions.setCart(orderData))
  } catch(e) {
    
  }
}

export function* cancelOrder(action: IActionCartCancelOrder) {
  try {
    const { user_id } = action.payload
    yield call(api.cart.cancelOrder, action.payload);
    yield put(cartActions.getCart({ user_id  }))
    yield put(coreActions.addNotification('Order Cancelled Successfully', 'notice'));
  } catch(e) {
    
  }
}

export function* orderDetailSaga(action: IActionCartGetOrderDetail) {
  try {
    const { data } =  yield call(api.cart.getOrderDetail, action.payload);
    const tireData = {
      image: data.branddetails.image,
      tire_image:data.tyre_image,
      brand: data.branddetails.brand,
      brand_arabic: data.branddetails.brand_arabic,
      year: data.prod_year,
      dealer_warranty: data.branddetails.dealer_warranty, 
      dealer_warranty_arabic: data.branddetails.dealer_warranty_arabic,
      madein: data.inventory.madein,
      f_size: data.tyreInfo.f_size,
      s_size: data.tyreInfo.s_size,
      t_size: data.tyreInfo.t_size,
      ...data.tyreInfoRear && {
        f_rear_size: data.tyreInfoRear.f_size,
        t_rear_size :data.tyreInfoRear.t_size,
        s_rear_size: data.tyreInfoRear.s_size,
        efficiency_rear: data.tyreInfoRear.efficiency,
        wet_traction_rear: data.tyreInfoRear.wet_traction,
      },
      price_front_vat: Number(data.price_front_vat),
      price_rear_vat: Number(data.price_rear_vat),
      price_front_discount: data.discount,
      price_rear_discount: data.discount,
      efficiency_front: data.tyreInfo.efficiency,
      
      wet_traction_front:data.tyreInfo.wet_traction,
    
      loadindex_front: data.loadratingfront.loadindex,
      loadindex_weight_front: data.loadratingfront.weight,
      ...data.loadratingrear && {
        loadindex_rear: data.loadratingrear.loadindex,
        loadindex_weight_rear: data.loadratingrear.weight,
      },
      ...data.speedratingrear && {
        speed_weight_rear: data.speedratingrear.weight,
        speed_rear: data.speedratingrear.speed,
      },
      speed_weight_front: data.speedratingfront.weight,
      speed_front: data.speedratingfront.speed,
      
      price: Number(data.price),
      price_rear: Number(data.price_rear),
      ...data.offer_id && {
        offer_id: Number(data.offer_id)
      }
      
    }
    console.log('tireData', tireData);
    
    yield put(cartActions.setOrderDetail({...data, tireData}))
  } catch(e) {
    
  }
}
export default [
  takeEvery(cartActions.ActionTypes.ADD_TO_CART, addToCartSaga),
  takeEvery(cartActions.ActionTypes.GET_CART, getCart),
  takeEvery(cartActions.ActionTypes.CART_CANCEL_ORDER, cancelOrder),
  takeEvery(cartActions.ActionTypes.CART_GET_ORDER_ORDER_DETAIL, orderDetailSaga),
  takeEvery(cartActions.ActionTypes.TAMARA_PRE_ORDER, tamaraPreOrder),
  takeEvery(cartActions.ActionTypes.COUPONCODE_APPLY, couponCodeApply),
  takeEvery(cartActions.ActionTypes.GET_TAMARA_CHECKOUT, tamaraCheckoutSaga),
  takeEvery(cartActions.ActionTypes.Get_TAMARA_CONFIRMATION, tamaraConfirmationSaga),
  takeEvery(cartActions.ActionTypes.Get_PAYMENT_CONFIRMATION, paymentConfirmationSaga),
  takeEvery(cartActions.ActionTypes.GET_TABBY_CHECKOUT, tabbyCheckoutSaga),
  takeEvery(cartActions.ActionTypes.GET_TABBY_PAYMENT, tabbyPaymentSaga),
];