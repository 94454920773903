import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from "react-helmet";

interface IProps {
   items: {
      metakeyword: string | undefined,
      metadescription: string | undefined,
      title:  string, 
      content: string 
}
}





export default class Policy extends React.Component<IProps> {

render () {
      const { items } = this.props;
      // console.log('items', items);
      var self = this;
  
      return (
         <>

         <Helmet>
         <title>{items ? items.title : ""}</title>
         <meta name="description" content={items ? items.metadescription : ""} />
         <meta name="keywords" content={items ? items.metakeyword : ""} />
         </Helmet>

         <section className="privacy-policy">
         <div className="container px-5">
            <div className="row justify-content-center">
               <div className="col-lg-12 mb-lg-5">
               <div className="privacy-content">
                  {ReactHtmlParser(items.content)}
               </div>
            </div>
         </div>

         
         </div>
         </section>
         </>
      )
    }
}