import axios from 'axios'
import { ICoreContact, ICorePages, ICoreUpdateCity } from '../../models/ICoreState';
import { api } from '..';
import { IUpdateLang } from '../../models/ILangState';

async function pages(params: ICorePages) {
    const url = params.slug === 'about_us' ? `/common/aboutuscontent` : `/common/getcontent`
    try {
        return api.postApi(url, params)
    } catch (e) {
        Promise.reject(e)
    }
}

async function about() {
    try {
        return api.postApi('/common/aboutuscontent')
    } catch (e) {
        Promise.reject(e)
    }
}

async function contactUs(params: ICoreContact) {
    console.log('params', params);
    try {
        return api.postApi('/user/contactus', params)
    } catch (e) {
        Promise.reject(e)
    }
}

async function getCities() {
    try {
        return api.postApi('/user/getcity')
    } catch (e) {
        Promise.reject(e)
    }
}

async function updateCity(paylod: ICoreUpdateCity) {
    try {
        return api.postApi('/user/updatecity')
    } catch (e) {
        Promise.reject(e)
    }
}

async function subscribeNewsletter(paylod: { email: string }) {
    try {
        return api.postApi('/common/submitnewsletter', paylod)
    } catch (e) {
        Promise.reject(e)
    }
}

async function updateLang(paylod: IUpdateLang) {
    try {
        return api.postApi('/user/updatelanguage', paylod)
    } catch (e) {
        Promise.reject(e)
    }
}

async function getLanguage(param: { lang: string }):Promise<any> {
    try {
        return api.postApi('/user/website-settings', param)
    } catch (e) {
        Promise.reject(e)
    }
}

async function getFaq():Promise<any> {
    try {
        return api.postApi('/common/faqlist')
    } catch (e) {
        Promise.reject(e)
    }
}

async function getMetaData(params: ICorePages) {
    try {
        return api.postApi('/common/getmetadata', params)
    } catch (e) {
        Promise.reject(e)
    }
}

export {
    pages,
    contactUs,
    getCities,
    updateCity,
    subscribeNewsletter,
    updateLang,
    getLanguage,
    getFaq,
    getMetaData
}