import { put, takeEvery, call, delay, select } from 'redux-saga/effects'
import { search as searchActions} from '../actions'
import { api } from '../services';
import { IActionGetBrand, IActionGetYear, IActionGetModel, IActionGetTires, IActionGetSize, IActionGetTireSize, IActionGetTireSizeRear, IActionGetRetailerDetail, IActionGetInstallCenter, IActionGetOrderDetail } from '../actions/searchActions';
import { getCity } from '../selectors';

/**
 * @param  {IActionGetBrand} action
 */
export function* getBrand(action: IActionGetBrand) {
  try {
    yield put(searchActions.setBrand([]))
    const { data }  = yield call(api.search.getBrand) 
    yield put(searchActions.setBrand(data))
  } catch(e) {
    console.log(e);
  }

}
/**
 * @param  {IActionGetYear} action
 */
export function* getYears(action: IActionGetYear) {
  try {
    yield put(searchActions.setYear([]))
    const { data }  = yield call(api.search.getYears, action.payload) 
    if (data)
    yield put(searchActions.setYear(data))
  } catch(e) {
    console.log(e);
  }
}
/**
 * @param  {IActionGetModel} action
 */
export function* getModel(action: IActionGetModel) {
  
  try {
    yield put(searchActions.setModel([]))
    const { data }  = yield call(api.search.getModel, action.payload) 
    console.log('getModel', data);
    
    if (data)
    yield put(searchActions.setModel(data))
  } catch(e) {
    console.log(e);
  }

}

/**
 * @param  {IActionGetTires} action
 */
export function* getTires(action: IActionGetTires) {
  try {
    const { ejbasicbrands = [], retailerbrands = [], ejnormalbrands = [] }  = yield call(api.search.getTires, action.payload) 
    console.log('ejbasicbrands', ejbasicbrands);
    yield put(searchActions.setTires([...ejnormalbrands, ...ejbasicbrands, ...retailerbrands]))
  } catch(e) {
    console.log(e);
  }

}

/**
 * @param  {IActionGetSize} action
 */
export function* getSize(action: IActionGetSize) {
  try {
    console.log('action.payload', action.payload);
    
    const { data }  = yield call(api.search.getSize, action.payload) 
    console.log('data', data);
    if (data)
    yield put(searchActions.setSize(data))
  } catch(e) {
    console.log('e', e);
    
    console.log(e);
  }

}


export function* getTireSize(action: IActionGetTireSize) {
  try {
    const { type } = action.payload
    console.log('action.payload', action.payload);
    
    const { data = [] }  = yield call(api.search.getTireSize, action.payload) 
    yield put(searchActions.setTireSize({ type, items: data  }))
  } catch(e) {
    console.log('e', e);
  }

}

export function* getTireSizeRear(action: IActionGetTireSizeRear) {
  try {
    const { type } = action.payload
    console.log('action.payload', action.payload);
    
    const { data = [] }  = yield call(api.search.getTireSize, action.payload) 
    yield put(searchActions.setTireSizeRear({ type, items: data  }))
  } catch(e) {
    console.log('e', e);
  }

}

// export function* getRetailerDetail(action: IActionGetRetailerDetail) {
//   try {
//     const { data }  = yield call(api.search.getRetailerDetail, action.payload) 
//     console.log('data', data);
    
//     yield put(searchActions.setRetailerOrderDetail(data))
//   } catch(e) {
//     console.log('e', e);
//   }
// }

export function* getOrderDetail(action: IActionGetOrderDetail) {
  try {
    console.log('action', action.payload);
    
    const { data }  = yield call(api.search.getOrderDetail, action.payload) 
    console.log('getOrderDetail', data);
    
    yield put(searchActions.setOrderDetail(data))
  } catch(e) {
    console.log('e', e);
  }
}

export function* getInstallCenter(action: IActionGetInstallCenter) {
  try {
    const city = yield select(getCity)
    const { installCenters }  = yield call(api.search.getInstallCenter, { ...action.payload, city_id: city.id}) 
    yield put(searchActions.setInstallCenter(installCenters))
  } catch(e) {
    console.log('e', e);
  }
}



export default [
  takeEvery(searchActions.ActionTypes.GET_BRAND, getBrand),
  takeEvery(searchActions.ActionTypes.GET_YEAR, getYears),
  takeEvery(searchActions.ActionTypes.GET_MODEL, getModel),
  takeEvery(searchActions.ActionTypes.GET_TIRES, getTires),
  takeEvery(searchActions.ActionTypes.GET_SIZE, getSize),
  takeEvery(searchActions.ActionTypes.GET_TIRE_SIZE, getTireSize),
  takeEvery(searchActions.ActionTypes.GET_TIRE_SIZE_REAR, getTireSizeRear),
  // takeEvery(searchActions.ActionTypes.GET_RETAILER_ORDER_DETAIL, getRetailerDetail),
  takeEvery(searchActions.ActionTypes.GET_ORDER_DETAIL, getOrderDetail),
  takeEvery(searchActions.ActionTypes.GET_INSTALL_CENTER, getInstallCenter),
];