import React from 'react';
import { images } from '../../../../constants/Images';
import { Link } from 'react-router-dom';
import { PATH } from '../../../../navigation/NavigationPath';
import { IOrder, TOrderStatus } from '../../../../models/IOrderState';
import moment from "moment"
import { Filter } from '../../../../utils';
import config from '../../../../constants/config';
import { typeUser } from '../../../../models/IUserState';
import { i18n } from '../../../../services';
import { withTranslation } from 'react-i18next';

interface IProps {
   items: IOrder
   role: typeUser
   wareHouseType: "Normal" | "Basic"
   onUpdateStatus: (status: TOrderStatus, order_id: string) => void;
}

interface IState {
}


const EFFICIENCY_LEVEL = [{color: '#C00000', label: 'Very Poor'}, {color: '#FF5A35', label: 'Poor'}, {color: '#FFDB00', label: 'Average'}, {color: '#94BC00', label: 'Good'}, {color: '#09B34D', label: 'Very Good'}, {color: '#548235', label: 'Excellent'}]



class OrderCard extends React.PureComponent<IProps, IState> {

   renderStatus = () => {
      console.log('i18n.language', i18n.language);
      
      const { items } = this.props;
      const status  = ['Confirmed', 'Under Process', 'On The Way', 'Ready for Installation', 'Cancelled', 'Complete', 'No Show']
      const status_arabic  = [i18n.t(`wd.order.element.status.confirmed`), i18n.t(`wd.order.element.status.under_process`), i18n.t(`wd.order.element.status.on_the_way`), i18n.t(`wd.order.element.status.ready_for_installation`), i18n.t(`wd.order.element.status.cancelled`), i18n.t(`wd.order.element.status.complete`), i18n.t(`wd.order.element.status.no_show`)]
      return status.map((row: string, index: number) => {
         return (
            <option value={row} selected={items.order_status === row}>{i18n.language === 'en' ? row : status_arabic[index]}</option>
         )
      })
   }

   renderStatusOfDealer = () => {
      console.log('i18n.language', i18n.language);
      
      const { items } = this.props;
      const status  = ['Ready for Installation', 'Complete']
      const status_arabic  = [i18n.t(`wd.order.element.status.ready_for_installation`), i18n.t(`wd.order.element.status.complete`)]
      return status.map((row: string, index: number) => {
         return (
            <option value={row} selected={items.order_status === row}>{i18n.language === 'en' ? row : status_arabic[index]}</option>
         )
      })
   }

    render() {
       const { items, role, wareHouseType, onUpdateStatus } = this.props;
      return(
         <li>
         <div className="u-list-box">
            <div className="orders-top">
               <div className="u-list-top-col"> <span>{i18n.t('wd.order.element.order_number')}   :</span> {items.code} </div>
               <div className="u-list-top-col"> <span> {i18n.t('wd.order.element.order_date')} : </span> {moment(items.add_date).format("DD-MM-YYYY  hh:mm A")}</div>
               <div className="u-list-top-col"> <span>{i18n.t('wd.order.element.customer_no')} : </span>  {items.customer.primary_mobile} </div>
               {
                  role === 'warehouse' ? (
                     <div className="u-list-top-col"> <span> {i18n.t('wd.order.element.install_center')} : </span> 
                         {Filter.translate(items.installcenter.name, items.installcenter.name_ar)} 
                     </div>
                  ) : (
                     <div className="u-list-top-col"> <span>{i18n.t('wd.order.element.vendor')} : </span> 
                        {items.vendor?.shop_name} 
                     </div>
                  )
               }
               {
                  role === 'warehouse' ? (
                     <div className="u-list-top-col">
                        <span> {i18n.t('wd.order.element.status')}  : </span> 
                        {
                           items.order_status === 'Cancelled' ? <span style={{ color: 'red' }}>{i18n.t('wd.order.element.status.cancelled')}</span> : (
                              <select  onChange={(e: any) => onUpdateStatus(e.target.value, items.id)}>
                           {this.renderStatus()}
                        </select>
                           )
                        }
                        
                     </div>
                  ) : (
                     <div className="u-list-top-col">
                        <span> {i18n.t('wd.order.element.status')}  : </span> 
                        {
                           items.order_status === 'Cancelled' ? <span style={{ color: 'red' }}>{i18n.t('wd.order.element.status.cancelled')}</span> : (
                              <select  onChange={(e: any) => onUpdateStatus(e.target.value, items.id)}>
                           {this.renderStatusOfDealer()}
                        </select>
                           )
                        }
                        
                     </div>
                  )
               }   
               
            </div>
            <div className="row">
               <div className="u-brand-logo col-md-3">
                  <span className="u-brand-col-left">
                  <img src={`${config.imageBaseUrl}${items.branddetails.image}`} />
                  </span>
                  <span className="u-brand-col-right">
                  <span className="u-list-year">  {items.prod_year}</span>
                  <span className="u-list-brand"><b>{Filter.translate(items.branddetails.brand, items.branddetails.brand_arabic)}</b> </span>  
                  {
                     items.madein && (
                        <span className="u-list-made"><span>{i18n.t('wd.order.element.madein')}: {items.madein}</span></span>
                     )
                  }
                   
               <span className="u-list-warrenty">{i18n.t('wd.order.element.dealer_warranty')}: <span className="bin">  {Filter.translate(items.branddetails.dealer_warranty, items.branddetails.dealer_warranty_arabic)}  </span></span> 
                  </span>
               </div>
               <div className="rear-size col-md-6">
                  <div className="clearfix u-border-com-box d-flex align-items-centers">
                     <div className="order-main u-top-mid-col">
                        <div className="order-title"><span>{ items.price_rear ? 'Front Size' : 'Tire Size' } : </span> <b>{`${items.f_size}/${items.s_size}/${items.t_size}`}</b></div>
                        


                     </div>
                     <div className="order-add size">
                        <div className="order-title"> <b>{Number(items.price_rear) > 0  ?  i18n.t(`common.label.front_qty`) : i18n.t(`cart.element.tire_qty`)} : {items.quantity}</b></div>
      <div className="order-add"><span>{i18n.t('common.label.unit_price')} </span> <b className='currency_parent'><img className='riyal_currency_cls' src={images.currencyImage} /> {items.unit_price_front} </b> </div>
                     </div>
                    
                  </div>
                   {
                           (role === 'warehouse' && wareHouseType === 'Normal') &&  (
                              <div className="order-good">
                                 {
                                    items.speedratingfront.speed && (
                                       <div className="order-add"><span>{i18n.t('wd.order.element.speed')} :</span> <b>{items.speedratingfront.speed}</b> </div>
                                    )
                                 }

                              {
                                 items.loadratingfront.loadindex && (
                                    <div className="order-add"><span>{i18n.t('wd.order.element.load')} :</span> <b>{items.loadratingfront.loadindex}</b> </div>
                                 )
                              }

                                    {
                                       items.efficiency_front && (
                                          <div className="order-add"><span>{i18n.t('wd.order.element.fuel_efficiency')} :</span> <b> 
                                          {EFFICIENCY_LEVEL[Number(items.efficiency_front)-1].label} </b> </div>
                                       )  
                                    }

                             {
                                items.wet_traction_front && (
                                 <div className="order-add"><span>{i18n.t('wd.order.element.wet_traction')} : </span> <b>{EFFICIENCY_LEVEL[Number(items.wet_traction_front)-1].label}</b> </div>
                                )
                             }

                                    
                                 </div>
                           )
                        }
{
   items.price_rear && (
      <>
      <div className="clearfix u-border-com-box d-flex align-items-center">
      <div className="order-main u-top-mid-col">
         <div className="order-title"><span>{i18n.t('common.label.rear_size')} : </span> <b>{`${items.f_size_rear}/${items.s_size_rear}/${items.t_size_rear}`}</b></div>
         


      </div>
      <div className="order-add size">
         <div className="order-title"> <b>{i18n.t('common.label.rear_qty')} {items.quantity_rear}</b></div>
<div className="order-add"><span>{i18n.t('common.label.unit_price')}</span> <b className='currency_parent'> <img className='riyal_currency_cls' src={images.currencyImage} /> {items.unit_price_rear} </b> </div>
      </div>
   </div>
   {
            (role === 'warehouse' && wareHouseType === 'Normal') && (
               <div className="order-good">
                  {
                     items.speedratingrear.speed && (
                        <div className="order-add"><span>{i18n.t('common.label.speed')} :</span> <b>{items.speedratingrear.speed}</b> </div>
                     )
                  }   
                  
                  {
                     items.loadratingrear.loadindex && (
                        <div className="order-add"><span>{i18n.t('wd.order.element.load')}  :</span> <b>{items.loadratingrear.loadindex}</b> </div>
                     )
                  }
                  
                  {
                     items.efficiency_rear && (
                        <div className="order-add"><span>{i18n.t('common.label.fuel_efficiency')} :</span> <b> 
                                          {EFFICIENCY_LEVEL[Number(items.efficiency_rear)-1].label} </b> </div>
                     )
                  }
                  
                  
                  {
                     items.wet_traction_rear && (
                        <div className="order-add"><span>{i18n.t('wd.order.element.wet_traction')} : </span> <b>{EFFICIENCY_LEVEL[Number(items.wet_traction_rear)-1].label}</b> </div>
                     )
                  }
                  
               </div>
            )
         }
   </>
   )
}
     


                
               </div>
               <div className="col-sm-3">
                  <div className="order-good1">
                     <div className="order-add"><span>{i18n.t('common.label.subtotal')} 
      </span> <b className='currency_parent'> <img className='riyal_currency_cls' src={images.currencyImage} /> {items.sub_total}  </b> 
                     </div>
                     <div className="order-add u-dis"><span>{i18n.t('common.label.discount')}
                        </span> <b> {items.discount_percent}% </b> 
                     </div>
                     
                     <div className="order-add"><span>{i18n.t('common.label.vat')} 
                        </span> <b className='currency_parent'> <img className='riyal_currency_cls' src={images.currencyImage} /> {items.vat}  </b> 
                     </div>
                     <div className="order-add">
                        <span className="u-list-tot"> {i18n.t('common.label.grand')} : <span className='currency_parent'><img className='riyal_currency_cls' src={images.currencyImage} /></span> {items.grand_total} </span>  
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </li>   
      )
    }
}

export default withTranslation()(OrderCard)