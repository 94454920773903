import React, { createRef } from 'react';
import { images } from '../../../../constants/Images';
import { ISearchBrand, ISearchTireCache } from '../../../../models/ISearchState';
import config from '../../../../constants/config';
import EjNormal from '../EjNormal';
import { withTranslation } from 'react-i18next';
import { Filter } from '../../../../utils';
import { i18n } from '../../../../services';
import { Loader } from '../../../../components/Base';

interface IProps {
  items: ISearchTireCache
  onChooseLocation?: (id: number) => void;
  onHomeInstall?: (id: number) => void;
}

interface IState {

}



class DetailModal extends React.PureComponent<IProps, IState> {

    render() {
      const { items, onChooseLocation, onHomeInstall} = this.props
      if (!items)
       return <Loader/>
      return (
        <>
        <div className="modal-body">
        <div className="row">
        <div className="col-lg-12">
          <div className="upper-details">
            <div className="inner-box">
             <div className="image-box">
               <figure className="image"><img src={`${config.imageBaseUrl}${items.tire_image}`} alt="" /></figure>
             </div>

             <div className="content-box">
               <div className="upper-box">
                 <div className="listing-heading">
                   <h3><a href="javascript:void:(0);">
                    {/* {Filter.translate(items.brand, items.brand_arabic)}  */}
                    {items.model_name}
                    {/* <span>{items.model_name}</span> */}
                    </a></h3>
                 </div> 
                 {
                   items.runflat == 1 && (
                    <a href="javascript:void:(0);" className="runflat">{i18n.t(`common.label.runflat`)}</a>
                   )
                 }
                  
               </div>
               <ul className="listing-info">
                <li><span>{i18n.t(`common.label.year`)}</span> {items.year}</li>
                <li><span>{i18n.t(`common.label.warranty`)}</span> <span className="color_text">{Filter.translate(items.dealer_warranty, items.dealer_warranty_arabic)}</span></li>
                <li><span>{i18n.t(`common.label.madein`)}</span> {items.madein}</li>
               </ul>

             </div>
           </div>
        </div>
        <div className="col-lg-12 p-0">
           <div className="tire_description">
             <div className="modal_contentwrap">
               <ul className="trire_front_rear">
                 {
                   items.price_rear ? (
                      <>
                          <li>{i18n.t(`common.label.front_tire`)} <span>{`${items.f_size}/${items.s_size}R-${items.t_size}`}</span></li>

                          <li>{i18n.t(`common.label.rear_tire`)} <span>{`${items.f_rear_size}/${items.s_rear_size}R-${items.t_rear_size}`}</span></li>

                      </>
                   ) : (
                    <li>{i18n.t(`common.label.tire`)} <span>{`${items.f_size}/${items.s_size}R-${items.t_size}`}</span></li>
                   )
                 }
             
               </ul>
               <div className="tire_decription_details tirbox">
                     <EjNormal items={items}/>
                     {
                       (onChooseLocation || onHomeInstall) && (
                        <ul className="price_listing">
                        {
                          items.price_rear ? (
                           <>

                             {
                             items.offer_id ? ( 
                              <>
                              <li>{i18n.t(`common.label.Original_Price`)}
                              {/* {i18n.t(`sar`)} */}
                               <span className="tire_details discountPrice"><span><span className="cutpricelabel currency_parent"><img className='riyal_currency_cls' src={images.currencyImage} />{items.price_front_vat}</span> </span> /({i18n.t(`common.label.inc_vat`)})</span>
                               </li>
                               <li>{i18n.t(`common.label.Original_Price`)}
                                   <span className="tire_details discountPrice"><span><span className="cutpricelabel currency_parent"><img className='riyal_currency_cls' src={images.currencyImage} /> {items.price_rear_vat}</span> </span> /({i18n.t(`common.label.inc_vat`)})</span>
                               </li>
                                <li>{i18n.t(`common.label.discounted_price`)}
                                 <span className="tire_details"><span className='currency_parent'>
                                 <img className='riyal_currency_cls' src={images.currencyImage} /> {items.price_front_discount.toFixed(2)} </span> / ({i18n.t(`common.label.inc_vat`)})
                                 </span>
                                 </li>
                                 <li>{i18n.t(`common.label.discounted_price`)}
                                 <span className="tire_details"><span className='currency_parent'>
                                 <img className='riyal_currency_cls' src={images.currencyImage} /> {items.price_rear_discount.toFixed(2)} </span> / ({i18n.t(`common.label.inc_vat`)})
                                 </span>
                                 </li>
                               </>
                             ) : (
                             <>
                              
                               <li>{i18n.t(`common.label.avg_retail_price`)}
                                 <span className="tire_details"><span>
                                 <span className="cutpricelabel currency_parent"><img className='riyal_currency_cls' src={images.currencyImage} /> {((items.price_front_vat + 4) /.9).toFixed(2)} </span> </span> / ({i18n.t(`common.label.inc_vat`)})
                                 </span>
                                 </li>
                                 <li>{i18n.t(`common.label.avg_retail_price`)}
                                 <span className="tire_details"><span>
                                 <span className="cutpricelabel currency_parent"> <img className='riyal_currency_cls' src={images.currencyImage} /> {((items.price_rear_vat + 4) /.9).toFixed(2)} </span> </span> / ({i18n.t(`common.label.inc_vat`)})
                                 </span>
                                 </li>
                                 <li>{i18n.t(`common.label.our_price`)}
                               <span className="tire_details"><span className='currency_parent'><img className='riyal_currency_cls' src={images.currencyImage} /> {items.price_front_vat} </span> /({i18n.t(`common.label.inc_vat`)})</span>
                               </li>
                               <li>{i18n.t(`common.label.our_price`)}
                                   <span className="tire_details"><span className='currency_parent'><img className='riyal_currency_cls' src={images.currencyImage} /> {items.price_rear_vat} </span> /({i18n.t(`common.label.inc_vat`)})</span>
                               </li>
                               </>
                             )
                           }
                           </>
                          ) : (
                          <>    
                                             
                           {
                             items.offer_id ? (
                               <> 
                               <li>{i18n.t(`common.label.Original_Price`)}
                               <span className="tire_details discountPrice"><span><span className="cutpricelabel currency_parent"><img className='riyal_currency_cls' src={images.currencyImage} /> {items.price_front_vat}</span> </span> /({i18n.t(`common.label.inc_vat`)})</span>
                               </li>   
                               <li>{i18n.t(`common.label.discounted_price`)}
                               <span className="tire_details"><span className='currency_parent'>
                               <img className='riyal_currency_cls' src={images.currencyImage} /> {items.price_front_discount.toFixed(2)} </span> / ({i18n.t(`common.label.inc_vat`)})
                               </span>
                               </li>
                               </>
                             ) : (
                               <>
                                 
                               <li>{i18n.t(`common.label.avg_retail_price`)}
                               <span className="tire_details">
                                <span>
                                 <span className="cutpricelabel currency_parent"><img className='riyal_currency_cls' src={images.currencyImage} /> {((items.price_front_vat + 4) /.9).toFixed(2)}</span> 
                                 </span> / ({i18n.t(`common.label.inc_vat`)})
                               </span>
                               </li>
                               <li>{i18n.t(`common.label.our_price`)}
                               <span className="tire_details"><span className='currency_parent'><img className='riyal_currency_cls' src={images.currencyImage} /> {items.price_front_vat} </span> /({i18n.t(`common.label.inc_vat`)})</span>
                               </li> 
                               </>
                             )
                           }                                               
                            </>
                          )
                        }
                    
                        </ul>
                       )
                     }

               </div>
             </div>
           </div>
        </div>
       </div>
     </div>
     </div>
{
  onChooseLocation && (
    <div className="modal-footer modal-footer-fixed">
    <button 
      type="button" 
      className="btn btn-primary" 
      id="choose_locationbtn" 
      onClick={() => onChooseLocation(items.inventory_id)}
      // style={{marginBottom:'70px'}}
    >
      {i18n.t(`tire.detail.button.choos_location`)}
    </button>
 </div>
  )
}
{
  onHomeInstall && (
    <div className="modal-footer modal-footer-fixed">
    <button 
      type="button" 
      className="btn btn-primary" 
      id="choose_locationbtn" 
      onClick={() => onHomeInstall(items.inventory_id)}
      // style={{marginBottom:'70px'}}
    >
      {i18n.t(`tire.detail.button.choos_location`)}
    </button>
 </div>
  )
}

     </>
      )
    }
}

export default withTranslation()(DetailModal)