import React, { createRef } from 'react';
import { images } from '../../../../constants/Images';
import { ISearchBrand } from '../../../../models/ISearchState';
import config from '../../../../constants/config';
import { Filter } from '../../../../utils';
import { ICartData } from '../../../../models/ICartState';
import { Container,Loader } from '../../../../components/Base';
import moment from "moment"
import { withTranslation } from 'react-i18next';
import { i18n } from '../../../../services'; 
import './Cart.css'
import { AlertModal } from '../../../../components';

import ReactHtmlParser from 'react-html-parser';
import IRootState, { ICartState, IUserState, ISearchState, IAuthState} from '../../../../models';
import { NONAME } from 'dns';

interface IProps {
  items: ICartData
  onCancle: (order_id: string, type: 'EJ' | 'Retailer') => void;
  onGoMAp: () => void
  canNotCancel: () => void;
  callTo: (phone: string, title: string) => void;
  mapMessage:(labeltext: string, mapLink: string) => void;
  goToSpecs: (orderId: string) => void;
}

interface IState {
  loading:boolean
  isShowAlertModal2: boolean
	popupMsg:string
}


class Cart extends React.PureComponent<IProps, IState> {
  state: IState = {
    loading:false,
    isShowAlertModal2: false,
		popupMsg:"",
  }


  status = ['Confirmed', 'Under Process', 'On The Way', 'Ready for Installation']
  

  showMsg(){
    alert(i18n.t(`msg_click_on_getdirection_popup`));
  }

    renderProgress = () => {
      
      this.setState({loading:false})
      const { items } = this.props
      const status = ['Confirmed', 'Under Process', 'On The Way', 'Ready for Installation']
      const statusTranlation = [i18n.t(`cart.element.progress.confirmed`), i18n.t(`cart.element.progress.under_process`), i18n.t(`cart.element.progress.on_the_way`), i18n.t(`cart.element.progress.ready_to_install`)]
      const statusIndex = status.map((value)=> value.toLocaleUpperCase()).indexOf(items.order_status.toLocaleUpperCase())
      if (statusIndex < 0 || items.record_type === 'Retailer') {
        return null
      }
      const activeIndex = 0
      return (
        <div className="md-stepper-horizontal" >
          {
                  status.map(( row, index ) => {
                    return (
                      <a href="javascript:void(0);" className={`md-step  ${statusIndex > index ? 'confiremd' : statusIndex === index ? 'active' : '' }`}>
                      <div className="md-step-circle">
                        {
                          statusIndex >= index && ( <img src={images.checkLogin} className="confirmed_check" /> )
                        }
                        
                      </div>
                      <div className="md-step-title">{statusTranlation[index]}</div>
                      <div className="md-step-bar-left"></div>
                      <div className="md-step-bar-right"></div>
                    </a>
                    )
                  })
          }
        </div>
      )

    }

    openPromotionPopup = (description : any) => {
      const { isShowAlertModal2, popupMsg } = this.state;
      this.setState({ isShowAlertModal2: !isShowAlertModal2, popupMsg: description });
      }
  
    toggelAlertModal2 = () => {
      const { isShowAlertModal2 } = this.state;
      this.setState({ isShowAlertModal2: !isShowAlertModal2 })
    }

    render() {
      const { items, onCancle, onGoMAp, canNotCancel, callTo, goToSpecs, mapMessage } = this.props

      // console.log('itemsitemsitems===>',items);
      let promotions_name = "";
      let promotions_name_rear = "";
      let promotions_description = "";
      let promotions_description_arabic = "";
      let promotions_description_rear = "";
      let promotions_description_arabic_rear = "";
      if(items.promotions){
        promotions_name = items.promotions.name;
        promotions_description = items.promotions.description;
        promotions_description_arabic = items.promotions.description_arabic;
      }
      if(items.promotions_rear){
        promotions_name_rear = items.promotions_rear.name;
        promotions_description_rear = items.promotions_rear.description;
        promotions_description_arabic_rear = items.promotions_rear.description_arabic;
      }

      if(this.state.loading){
        return <Loader />
      }

      const install_center_universal = items.install_center_universal ? items.install_center_universal : 0;
      return (
        
        <div className="cart_listingwrap" >
          {/* {
            this.state.loading ?
              (
                <div className="preloading">
                  <div className="wrap-preload">
                      <div className="cssload-loader"></div>
                  </div>
                </div>
            ) : ""
          } */}
        <div className="list-card-main">
        <div className="cart_top">
          <div className="booking_date_cart">
            <p className='bookno'><span>
            {items.record_type === 'EJ' ? i18n.t(`cart.element.order_no`) : i18n.t(`cart.element.booking_no`) } 
              </span> {items.code}</p>
            <p className='datename'>
             <span>{i18n.t(`cart.element.date`)}
            </span> {moment(items.created_at).format('DD/MM/YYYY, h:mm a')}</p>
          </div>
          {
        items.order_status === 'Complete' && (
          <a href="javascript:void(0);" className="order_complete">
            {
             items.record_type === 'EJ' ? i18n.t(`cart.element.order_completed`) : i18n.t(`cart.element.booking_completed`)
            }
          </a>
        )
      }


      {
        items.order_status === 'Cancelled' && (
          <a href="javascript:void(0);" className="order_complete" style={{ color: 'red' }}>
            {
              items.record_type === 'EJ' ? i18n.t(`cart.element.order_cancelled`) : i18n.t(`cart.element.booking_cancelled`)
            }
          </a>
        )
      }

      {
       (items.order_status === 'Ready for Installation' && items.record_type === 'Retailer') && (
          <a href="javascript:void(0);" className="order_complete" style={{ color: '#8acd36' }}>{i18n.t(`cart.element.progress.ready_to_install`)}</a>
        )
      }


      
      { this.renderProgress() }

        </div>

        <div className="cartinner">
            <div className="cartrowdata">
              <div className="leftcol">
                <div className="card-top-box">
                  <div className="brand-list">
                    <div className="brand-logo">
                      <img src={`${config.imageBaseUrl}${items.branddetails ? items.branddetails.image : "/tire_default.jpeg"}`} />
                    </div>
                    <div className="brand-col brandname">
                      <div className="insidecol">
                        <span>{items.prod_year} </span>
                        <h2>{Filter.translate(items.branddetails ? items.branddetails.brand : "", items.branddetails ? items.branddetails.brand_arabic : "")}</h2>
                      </div>
                    </div>
                  </div>

                  <ul className="brand-list rightbrand">

                      {
                        items.inventory.madein && (
                          <li className="brand-col">
                            <div className="insidecol">
                              <span>{i18n.t(`common.label.madein`)}</span> <h2>{items.inventory.madein}</h2>     
                            </div>
                          </li>
                        )
                      }
                    <li className="brand-col ">
                      <div className="insidecol">
                        <span>{i18n.t(`common.label.delaer_warranty`)} </span>
                        <h2 className="bin">{Filter.translate(items.branddetails ? items.branddetails.dealer_warranty : "", items.branddetails ? items.branddetails.dealer_warranty_arabic : "")}</h2>
                      </div>
                    </li>
                    <li className="brand-col">
                    {
                      items.tyreInfo && (
                      <a href={`javascript:void(0);`} className='btn btn-border' onClick={() => goToSpecs(items.id)}>{i18n.t(`cart.element.link.tire_specs`)}</a>
                      )
                    }
                    </li>
                  </ul>

                </div>
                <div className="card-top-box">

                  <ul className="brand-list frontlist">

                    <li className="brand-col">
                      <div className="insidecol">
                        <span>{Number(items.price_rear) > 0  ?  i18n.t(`common.label.front_size`) : i18n.t(`cart.element.tire_size`)} </span>
                        <h2>{`${items.customerrequestdetail.f_size}/${items.customerrequestdetail.s_size}/${items.customerrequestdetail.t_size}`}</h2>
                      </div>
                    </li>
                    <li className="brand-col ">
                      <div className="insidecol">
                        <span>{Number(items.price_rear) > 0  ?  i18n.t(`common.label.front_qty`) : i18n.t(`cart.element.tire_qty`)} </span>
                        <h2 className="">{items.quantity}</h2>
                      </div>
                    </li>
                    <li className="brand-col ">
                      <div className="insidecol">
                        <span>{i18n.t(`common.label.unit_price`)} </span>
                        <h2 className=""><img src={images.currencyImage} alt="" /> {items.single_front_price} </h2>
                      </div>
                    </li>

                  </ul>

                  { Number(items.price_rear) > 0 && (
                  <ul className="brand-list rearlist">

                    <li className="brand-col">
                      <div className="insidecol">
                        <span>{i18n.t(`common.label.rear_size`)} : </span>
                        <h2>{`${items.f_size_rear}/${items.s_size_rear}/${items.t_size_rear}`}</h2>
                      </div>
                    </li>
                    <li className="brand-col ">
                      <div className="insidecol">
                        <span>{i18n.t(`common.label.rear_qty`)} </span>
                        <h2>{items.quantity_rear}</h2>
                      </div>
                    </li>
                    <li className="brand-col ">
                      <div className="insidecol">
                        <span>{i18n.t(`common.label.unit_price`)} </span>
                        <h2 className=""><img src={images.currencyImage} alt="" /> {items.single_rear_price}</h2>
                      </div>
                    </li>
                  </ul>
                   )  
                  }

                </div>


              {items.installcenter && items.type !== "Install at Home" &&(
                <div className="block-details" style={{display: items.order_status === "Cancelled" || items.order_status === "Complete" ? 'none' : "block"}}>
                  <div className="listing-block jyoti1">
                    <div className="inner-box">
      
                      <div className="content-box">
                        <div className="upper-box tireinfo_istingwrap">
                          <div className="colbox listing-heading">
                            <div className="image-box img_first">
                            <img src={`${config.imageBaseUrl}${items.installcenter.image_name}`} alt="" />
                            </div>
                            <div className="innercol">
                            <h3><a href="javascript:void:(0);">{ Filter.translate(items.installcenter.name, items.installcenter.name_ar) }</a></h3>
                            <p className="address">{ Filter.translate(items.installcenter.display_location, items.installcenter.display_location_ar) }</p>
                          </div>
                          </div>
                          <div className="colbox working_timeing">
                            <div className="innercol">
                            <p>{i18n.t(`common.label.working_hour`)} </p>
                            <span className="full_time">{ Filter.renderHours(items.installcenter.working_hours, items.installcenter.working_hours_ar)}</span>
                            </div>
                          </div>
                        </div>
                        <ul className="contact_shop">
                          <li>
                          <a href={`javascript:void(0);`} onClick={() => callTo(items.warehouse.primary_mobile, i18n.t(`cart.element.button.call`))}>{i18n.t(`cart.element.button.call`)}</a>
                          </li>
                          <li>

                          {  
                            (items.order_status === "Confirmed" || items.order_status === "Under process" || items.order_status === "On the way") ? 
                            <a onClick={() => mapMessage(i18n.t(`msg_click_on_getdirection_popup`), `https://www.google.com/maps/place/${items.installcenter.latitude},${items.installcenter.longitude}`)} href="javascript:void(0);" >{i18n.t(`cart.element.button.direction`)}</a>  : 
                            <a href={`https://www.google.com/maps/place/${items.installcenter.latitude},${items.installcenter.longitude}`} target="_blank">{i18n.t(`cart.element.button.direction`)}</a> 
                          }  

                          </li>
                        </ul>
                      </div>

                    </div>
                    <p className="deliver_time" style={(items.order_status == "Cancelled" || items.order_status == "Complete") ? {display:"none"} : {display:"block"}}>{ReactHtmlParser(i18n.t(`cart.label.fullfilled`, {
                          name: Filter.translate(items.warehouse.display_name, items.warehouse.display_name_ar), hours: items.warehouse.delivery_hours
                        }))}
                        </p>  
                  </div>
                

                </div>
              )}


              {items.vendor && items.type !== "Install at Home" &&(
                <div className="block-details" style={{display: items.order_status === "Cancelled" ? 'none' : "block"}}>
                  <div className="listing-block jyoti3">
                    <div className="inner-box">
      
                      <div className="content-box">
                        <div className="upper-box tireinfo_istingwrap">
                          <div className="colbox listing-heading">
                            <div className="image-box img_first">

                              <img src={`${config.imageBaseUrl}${items.vendor.image}`} alt="" />
      
                            </div>
                            <div className="innercol">
                            <h3><a href="javascript:void:(0);">{ Filter.translate(items.vendor.shop_name, items.vendor.shop_name_ar) }</a></h3>
                            <p className="address">{ Filter.translate(items.vendor.location, items.vendor.location_ar) }</p>
                          </div>
                          </div>
                          <div className="colbox working_timeing">
                            <div className="innercol">
                            <p>{i18n.t(`common.label.working_hour`)}</p>
                            <span className="full_time">{ Filter.renderHours(items.vendor.working_hours, items.vendor.working_hours_ar) }</span>
                            </div>
                          </div>
                        </div>
                        { (items.order_status === "Cancelled") ? "" : (
                          <ul className="contact_shop">
                            <li> <a href={`javascript:void(0);`} className="btn btn-yellow" onClick={() => callTo(items.vendor.primary_mobile, i18n.t(`cart.element.button.call`))}>{i18n.t(`cart.element.button.call`)}</a></li>
                            <li><a href={`https://www.google.com/maps/?q=-${items.vendor.latitude},${items.vendor.longitude}`} className="btn btn-yellow" target={'_blank'}>{i18n.t(`cart.element.button.direction`)}</a></li>
                          </ul> 
                         ) }
                      </div>

                    </div>
                    <p className="deliver_time">{i18n.t(`cart.label.booking`)}</p>
                  </div> 
                </div>
              )}

                  {items.type == "Install at Home" &&(
                    <div className="block-details" style={{display: items.order_status === "Cancelled" || items.order_status === "Complete" ? 'none' : "block"}}>
                    <div className="listing-block jyoti2">
                    <div className="inner-box">
                      <div className="content-box">
                        <div className="upper-box tireinfo_istingwrap">
                          <div className="colbox listing-heading">
                            <div className="innercol">
                            <p className="deliver_time" style={(items.order_status == "Cancelled" || items.order_status == "Complete") ? {display:"none"} : {display:"block"}}>
                            {ReactHtmlParser(i18n.t(`cart.label.fullfilled_home`, {
                            name: Filter.translate(items.warehouse.display_name, items.warehouse.display_name_ar), hours: items.warehouse.delivery_hours
                          }))}
                              </p>
                            <div className="listing-heading">{items.delivery_address}</div>
                          </div>
                          </div> 
                        </div>
                        <ul className="contact_shop">
                           
                          {
                            items.warehouse && items.order_status != "Cancelled" && (<li> <a href={`javascript:void(0);`} className="btn btn-yellow" onClick={() => callTo(items.warehouse.primary_mobile, i18n.t(`cart.element.button.call`))}>{i18n.t(`cart.element.button.call`)}</a></li>)
                          }
                          {
                            items.vendor && (<li> <a href={`javascript:void(0);`} className="btn btn-yellow" onClick={() => callTo(items.vendor.primary_mobile, i18n.t(`cart.element.button.call`))}>{i18n.t(`cart.element.button.call`)}</a></li>)
                          }
                          
                        </ul>
                      </div>
  
                    </div> 
                  </div>
                  </div>
                  )
                }

              </div>


              <div className="rightcol">
                <div className="tablecontainer">

                   {/* Table Rows */}
                  <div className="table-row">
                    <div className="table-cell">{i18n.t(`common.label.subtotal`)} </div>
                    <div className="table-cell value-cell"> <span className="rcurrency"><img src={images.currencyImage} alt="" />{Number(items.quantity) * Number(items.single_front_price) + Number(items.quantity_rear) * Number(items.single_rear_price)}</span> </div>
                  </div>
                  <div className="table-row">
                    <div className="table-cell">{i18n.t(`common.label.installation`)} </div>
                    <div className="table-cell value-cell">
                      {
                        Number(items.installation_amount) > 0 ? (
                        <span className='rcurrency'> <img src={images.currencyImage} /> {items.installation_amount} </span> 
                      ) : (
                        <span className="tag freetag">{i18n.t(`common.free`)} </span>
                      )}
                    </div>
                  </div>
                  <div className="table-row">
                    <div className="table-cell">{i18n.t(`common.label.Delivery`)} : </div>
                    <div className="table-cell value-cell"> 
                      {
                        (Number(items.delivery_amount) > 0) ? (
                          <span className='rcurrency'> <img src={images.currencyImage} /> {items.delivery_amount} </span> 
                      ) : (
                        <span className="tag freetag">{i18n.t(`common.free`)} </span>
                      )}
                     </div>
                  </div>

                  {
                    Number(items.discount) > 0 && (

                      <div className="table-row">
                        <div className="table-cell">{i18n.t(`common.label.discount`)} </div>
                        <div className="table-cell value-cell"> <span className="rcurrency"><img src={images.currencyImage} alt="" /> {items.discount}</span> </div>
                      </div>
                  )}

                  {
                    Number(items.coupon_code_discount) > 0  && (

                      <div className="table-row">
                        <div className="table-cell">{i18n.t(`coupon_code_discount`)} : </div>
                        <div className="table-cell value-cell"> <span className="rcurrency"><img src={images.currencyImage} alt="" /> {items.coupon_code_discount}</span> </div>
                      </div>
                  )}

                  <div className="table-row">
                    <div className="table-cell">{i18n.t(`common.label.total`)} </div>
                    <div className="table-cell value-cell"> <span className="rcurrency"><img src={images.currencyImage} alt="" />{items.priceD}</span> </div>
                  </div>
                  <div className="table-row">
                    <div className="table-cell">{i18n.t(`common.label.vat`)}  </div>
                    <div className="table-cell value-cell"> <span className="rcurrency"><img src={images.currencyImage} alt="" />{items.vat}</span> </div>
                  </div>
                  <div className="table-row grandtotalrow">
                    <div className="table-cell">{i18n.t(`common.label.grand`)} </div>
                    <div className="table-cell value-cell"> <span className="rcurrency"><img src={images.currencyImage} alt="" />{items.grand_total}</span> </div>
                  </div>

                  

                </div>

                {(items.order_status === 'Cancelled') ? "" : ( 
                <div className="payinstall">
                  <div className="icon">
                  {(items.payment_type != 'Cash') ? (  
                        <img src={(items.payment_type ==='Tamara') ? images.tamaraImage : images.tabbyImage} alt="" />
                    ) : ( 
                        <img src={images.cashPayImage} alt="" />
                    )
                    }
                  </div>
                  <div className="content">
                  {(items.payment_type != 'Cash') ? (  
                        <h2>{(items.payment_type ==='Tamara') ? i18n.t(`purchase_via_tamara`) : i18n.t(`purchase_via_tabby`)}</h2>
                    ) : ( 
                      <h2>{i18n.t(`pay_at_shop`)}</h2>
                    )
                    }
                  </div>
                </div>
                )}

{promotions_name && (

<div className="promotionscards">
                   
                  <div className="content">
                 <p> {i18n.t(`included_gift`)} <span> {promotions_name}</span> <a href='javascript:void(0);' onClick={() =>this.openPromotionPopup(Filter.translate(promotions_description, promotions_description_arabic))}><span className='question_mark_cls'><img src={`${config.imageBaseUrl}/question_mark.png`} height={'20px'} width={'20px'} /></span></a></p>
                  </div>
                </div>
 
                  )}

                  {promotions_name_rear && (

<div className="promotionscards">  
                   <div className="content">
                  <p>{i18n.t(`included_gift`)}  <span> {promotions_name_rear} </span>  <a href='javascript:void(0);' onClick={() =>this.openPromotionPopup(Filter.translate(promotions_description_rear, promotions_description_arabic_rear))}><span className='question_mark_cls'><img src={`${config.imageBaseUrl}/question_mark.png`} height={'20px'} width={'20px'} /></span></a></p>
                   </div>
                 </div>
                  )}

                <div className="btnrow">

                { items.order_status !== 'Cancelled' && ( items.order_status !== 'Complete' && (  
                  <div className="pay_btn">
                  
                          <a 
                            href="javascript:void(0);" 
                            className="btn btn-yellow" 
                            onClick={
                              () => {
                                ((items.record_type === "EJ" && ['Confirmed', 'Under process'].indexOf(items.order_status) >= 0 ) 
                                || items.record_type === "Retailer" && ['Ready for Installation'].indexOf(items.order_status) >= 0 ) 
                                ? onCancle(items.id, items.record_type) : canNotCancel()                            
                              }
                            }
                            style={items.record_type === "EJ" && ['Confirmed', 'Under process'].indexOf(items.order_status) >= 0 ? { backgroundColor: '#ffc000', color: '#fff' } : items.record_type === "Retailer" && ['Ready for Installation'].indexOf(items.order_status) >= 0 ? { backgroundColor: '#ffc000', color: '#fff' }  : {}}
                          >
                              {i18n.t(`cart.element.button.cancel`)} 
                          </a>
                  </div>
                ) ) }
                </div>

              </div>
            </div>

          </div>


        {/* <div className="cart_inner"> */}
            
              <AlertModal
			show={this.state.isShowAlertModal2}
			handleClose={this.toggelAlertModal2}
			title={i18n.t(`gift_popup_details`)}
			description={this.state.popupMsg}
			actionType={'remove'}
			handleAction={()=> {}}
			isConfirmation={false}
			closeButtonName={i18n.t(`OK`)}
			// handleNavigation={() => history.push(PATH.CART)}
        />

        {/* </div> */}
      </div>
      </div>
      )
    }
}


export default withTranslation()(Cart)