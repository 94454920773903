import React, { createRef } from 'react';
import { images } from '../../../../constants/Images';
import { ISearchBrand, ISearchModel, ISearchTireCache } from '../../../../models/ISearchState';
import config from '../../../../constants/config';
import { i18n } from '../../../../services';
import { withTranslation } from 'react-i18next';
import MetaDataCls from '../../../Home/HomePage/MetaDataCls';

interface IProps {
  items: ISearchTireCache
  onGoBack: () => void;
}

interface IState {

}



class InstallCenterHeader extends React.PureComponent<IProps, IState> {

    render() {
      const { items, onGoBack } = this.props;
      return (
		<>
		{localStorage.setItem('metadataslug', 'location_selection')}
         <MetaDataCls />
        <header className="location_page_header">
			<div className="row">
				<div className="col-lg-12">
				<div className="listing-block">
					<div className="navbar-left-arrow mobile_arrow">
						<a href="javascript:void(0);" onClick={onGoBack}><img src={images.leftArrowBlack} /></a>      
					</div>
					<div className="inner-box location_header_mobile">
					<div className="navbar-left-arrow">
						<a href="javascript:void(0);" onClick={onGoBack}><img src={images.leftArrowBlack} /></a>      
					</div>
						<div className="image-box">
							<figure className="image"><img className="tire-brand-img-now" src={`${config.imageBaseUrl}${items.tire_image ? items.tire_image : items.image}`} alt=""/></figure>
						</div>
						<div className="content-box">
							<div className="upper-box">
							{
                items.tire_image && (
                  <div className="user-thumb"><img src={`${config.imageBaseUrl}${items.image}`} alt=""/></div>
                )
              }
							
							<div className="listing-heading">
								<span className="listing-year">{items.year}</span>
								<h3>
				                  	<a href="javascript:void:(0);">
				                  		{
							                i18n.language === 'ar' ? (
							                <span>{items.brand_arabic}</span>
							              ) : (
							                <span>{items.brand}</span>
							              )}
				                	</a>
				                </h3>
							</div> 
              {
                items.runflat === 1 && (
                  <a href="javascript:void:(0)" className="runflat">{i18n.t('common.label.runflat')}</a>
                )
              }
							
							<ul className="listing-info location_warrantylist">
            {
				items.madein && (
					<li><span>{i18n.t(`common.label.madein`)}</span> {items.madein}</li>
				)
			}
            
							</ul>            
							</div>
							<div className='tire-style-new'>
							{
		                        items.price_rear ? (
								<>	
								{/* {i18n.t(`sar`)} */}		
								<ul className="listing-info">						
		                          <li><span>{i18n.t(`common.label.front_price`)}</span> <div className='currency_parent' > <img className='riyal_currency_cls' src={images.currencyImage} /> {items.price_front_vat}</div> <small>({i18n.t(`common.label.inc_vat`)})</small></li>
		                          <li><span>{i18n.t(`common.label.front_qty`)} </span> {items.quantity}</li>
								  </ul>
								  <ul className="listing-info">
		                          <li><span>{i18n.t(`common.label.Rear_price`)}</span> <div className='currency_parent' > <img className='riyal_currency_cls' src={images.currencyImage} /> {items.price_rear_vat} </div><small>({i18n.t(`common.label.inc_vat`)})</small></li>
		                          <li><span>{i18n.t(`common.label.rear_qty`)}</span> {items.quantity_rear}</li>
		                        </ul>
								</>
		                      ) : (
		                        <ul className="listing-info">
		                          <li><span>{i18n.t(`common.label.tire_price`)}</span> <div className='currency_parent' ><img className='riyal_currency_cls' src={images.currencyImage} />  {items.price_front_vat}</div> <small>({i18n.t(`common.label.inc_vat`)})</small></li>
		                          <li><span>{i18n.t(`common.label.tire_qty`)}</span> {items.quantity}</li>
		                        </ul>
		                      )}

							</div>
							<div className="listing-bottom-box mapview-btn">
							<div className="listing-rates">
								<div className="left-area">
								<div className="total-price">{i18n.t(`common.label.total_price`)}</div> 
								<span><span className='currency_parent'><img className='riyal_currency_cls' src={images.currencyImage} /> {items.total_price} </span> <small>({i18n.t(`common.label.inc_vat`)})</small></span>
								</div>
							</div>
							</div>
						</div>      
					</div>
					</div>
					
							<div className="listing-bottom-box mob_locbtn">
							<div className="listing-rates">
								<div className="left-area">
								<div className="total-price">{i18n.t(`common.label.total_price`)}</div> 
								<span><div className='currency_parent'><img className='riyal_currency_cls' src={images.currencyImage} />{items.total_price} </div> <small>({i18n.t(`common.label.inc_vat`)})</small></span>
								</div>
							</div>
							</div>
				</div>
				</div>
		</header>
		</>
      )
    }
}

export default withTranslation()(InstallCenterHeader)