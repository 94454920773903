import React from 'react';
import { Container } from '../../../components/Base';
import { images } from '../../../constants/Images';
import IRootState, { ICoreState } from '../../../models';
import { connect } from 'react-redux';
import { core as coreActions } from '../../../actions'
import { validation, i18n } from '../../../services';
import { ICoreContact } from '../../../models/ICoreState';
import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

interface IProps {
  addNotification: ({}, {}) => void;
  contactUs: (data: ICoreContact) => void;
  getPage: ({  }) => void;
  core: ICoreState;
}

interface IState {
  isValidCaptcha: boolean
}

class ContactUsPage extends React.Component<IProps> {
  state = {
    formData: {
      fields: {
        access_key: 'r9ga6iv7ri1nc3ah4ra8u0h0an',
        lang: 'en',
        fullname: '',
        phone: '',
        message: '',
        captcha: ''
      },
      required: ['fullname', 'phone', 'message', 'captcha']
    },
    validation: {},
    isValidCaptcha: false
  }
  componentDidMount () {
   // this.props.addNotification('hello', 'notice')
   this.props.getPage({
    slug: 'contact_us'
  })
  }

  handleChange = (value : any, field: string) => {
    const { formData } = this.state;
    console.log('field', field);
    this.setState({ formData: { ...formData, fields: { ...formData.fields, [field]: value } } }, () => {
      const validate = validation.isValidate(this.state.formData)
      this.setState({ validation: validate })
    })
  } 

  onSubmit = () => {
    const { formData } = this.state;
    const { contactUs } = this.props;
    // console.log('formData1', formData);
    const validate = validation.isValidate(formData)
    this.setState({ validation: validate })
    if(formData.fields.fullname){
    if (!Object.keys(validate).length ) {
      const params = {...formData.fields, problem_text: formData.fields.message }
     //  delete params.message
      contactUs(params);
      this.setState({formData:{fields: {access_key: 'r9ga6iv7ri1nc3ah4ra8u0h0an', lang: 'en', fullname: '', phone: '', message: '', captcha: ''}, isValidCaptcha:false}});
    }
  }
    
    // console.log('validate', validate);
    
  }
  onBlur = () => {
    // const { formData } = this.state;
    // const validate = validation.isValidate(formData)
    // this.setState({ validation: validate })
  }

  renderError = (field: string) => {
    const { validation }: { [key in string] : any } = this.state;
    console.log('validation', validation);
    
    const keys = Object.keys(validation);
    console.log('keys', keys);
    
    if (keys.indexOf(field) >= 0) {
      return validation[field].message;
    }
    return ''
  }

  onCaptchaCahnge = (value: any) => {
    console.log("Captcha value:", value);
    this.handleChange(value, 'captcha')
    
  }

    render() {
      const { formData } = this.state;
      console.log('formData', formData);
      const contactUsData = this.props.core.pages.contactus;
      // console.log('corecorecorecore===========>', this.props.core.pages.contactus);
      
      return (
          <>

        <Helmet>
          <title>{contactUsData ? contactUsData.title : ""}</title>
          <meta name="description" content={contactUsData ? contactUsData.metadescription : ""} />
          <meta name="keywords" content={contactUsData ? contactUsData.metakeyword : ""} />
        </Helmet>

         <Container>
            <div>
            <section className="inner-page-banner center-text-banner bg-size-cover" style={{ background: '#fcc223' }}>
  <div className="tire-mark"><img src={images.innerTireMark} alt=""/></div>
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="list-heading text-center">
          <h3>{i18n.t(`contact.title`)}</h3>
        </div>
      </div>         
    </div>
  </div>
</section>
<section className="contact-section-1">
  <div className="container px-5">
    <div className="row justify-content-center">
      <div className="col-lg-12 mb-lg-5 text-center">
       <div className="main-heading">
        {/* <h6>Tire Manufacturer</h6> */}
        <h2>{i18n.t(`contact.subtitle`)}</h2>
        <p>{i18n.t(`contact.description`)}</p>
        <br />
        <p>{i18n.t(`contact.description2`)}</p>
        <br />
        <p>{i18n.t(`contact.description3`)}</p>
      </div>
    </div>
  </div>

  {/* <div className="row justify-content-center">
    <div className="col-lg-12 mb-lg-5 text-center">
      <div className="contact-details">
        <ul>
          <li><span className="contact-icon"><img src={images.contactIcon1} /></span>
            <div className="details">
              <strong>Visit Us:</strong>
              <p> Saudi Arabia </p>
            </div>
          </li>
          <li><span className="contact-icon"><img src={images.contactIcon2} /></span>
            <div className="details">
              <strong>Mail Us:</strong>
              <p> demo@mailinator.com </p>
            </div>
          </li>
          <li><span className="contact-icon"><img src={images.contactIcon3} /></span>
            <div className="details">
              <strong>Phone Us:</strong>
              <p>+1 (000) 000 000 000</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div> */}

</div>
</section>

<section className="contact-form">
  <div className="container px-lg-5">
    <div className="row">
      <div className="col-lg-12">
        <div className="form-box">
          <h3 className="form-title">{i18n.t(`contact.form.title`)}</h3>
          {/* <p>Your email address will not be published. Required fields are marked *</p> */}
          <form>
            <div className="form-row">
              <div className="form-group col-md-6">
                <input 
                  type="text" className="form-control" placeholder={i18n.t(`contact.form.placeholder.name`)} 
                  value={formData.fields.fullname} onChange={(e: any) => this.handleChange(e.target.value, 'fullname')}
                  onBlur={this.onBlur}
                />
                <span className='error-input'>{this.renderError('fullname')}</span>
              </div>
              <div className="form-group col-md-6">
                <input type="mobile" className="form-control" placeholder={i18n.t(`contact.form.placeholder.phone`)} 
                    value={formData.fields.phone} onChange={(e: any) => this.handleChange(e.target.value, 'phone')}
                />
                <span className='error-input'>{this.renderError('phone')}</span>
              </div>
            </div>
            {/* <div className="form-group">
              <input type="text" className="form-control" placeholder="Website" />
            </div> */}
            <div className="form-group">
              <textarea className="form-control" placeholder={i18n.t(`contact.form.placeholder.message`)} rows={4}
                  value={formData.fields.message} onChange={(e: any) => this.handleChange(e.target.value, 'message')}
              ></textarea>
              <span className='error-input'>{this.renderError('message')}</span>
            </div>
            <div style={{ marginBottom: '20px' }}>
            <ReCAPTCHA
              sitekey="6LdTiw0fAAAAAGLXcQgT3iyb12XAY-xQtR0xgz8f"
              onChange={this.onCaptchaCahnge}
              theme={'dark'}
              hl={i18n.language}
            />
            {
              this.renderError('captcha') && (
                <span className='error-input'>Please verify captcha</span>
              )
              
            }
            
            
            </div>
           
            <button type="button" className="btn btn-warning" onClick={this.onSubmit}>{i18n.t('contact.button.submit')}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div className="envelope-deco">
    <img src="img/mail-icon-1.png" className="deco-1" alt="" />
    <img src="img/mail-icon-3.png" className="deco-3" alt="" />
    <img src="img/mail-icon-2.png" className="deco-2" alt="" />
  </div>
</section>
            </div>
         </Container>
         </>
      )
    }
}



const mapStateToProps = (state: IRootState) => ({
  core: state.core,
});

const mapDispatchToProps = {
  addNotification: coreActions.addNotification,
  contactUs: coreActions.contactUs,
  getPage: coreActions.getPage
}



export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)<any>(ContactUsPage)) 