import React from 'react';

import { images } from '../../../constants/Images';
import IRootState, { ISearchState, ICoreState } from '../../../models';
import { connect } from 'react-redux';
import { Header, BrandCard, Years, SizeCard } from '../Components';
import { search as searcActions } from '../../../actions'
import { PATH } from '../../../navigation/NavigationPath';
import { withRouter } from "react-router";
import { ISearchGetYear, ISearchGetModel, ISearchBrand, ISearchGetSize, ISearchGetTires, ISearchGetTireSize, ISearchGetTireSizeRear, typeTireSize, ISearchSetTireSize } from '../../../models/ISearchState';
import { Modal, Input } from '../../../components';
import './SizePage.css'
import { validation } from '../../../services';
import { IHomeBanner } from '../../../models/IHomeState';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { core as coreActions } from '../../../actions'
import { Helmet } from "react-helmet";
import { Filter } from '../../../utils';
// import 'semantic-ui-css/semantic.min.css';
import './semantic.min.css';
import { Dropdown } from 'semantic-ui-react';

interface IProps {
  getBrand: () => void;
  search: ISearchState;
  history: any;
  getYear: (param: ISearchGetYear) => void;
  match: { params: { id: string } }
  location: { pathname: string, state: { id: string, path: string, bannerData?: IHomeBanner } }
  getModel: (param: ISearchGetModel) => void;
  getSize: (param: ISearchGetSize) => void;
  getTires: (param: ISearchGetTires) => void;
  getTireSize: (param: ISearchGetTireSize) => void;
  getTireSizeRear: (param: ISearchGetTireSizeRear) => void;
  core: ICoreState;
  setTireSize: (param: ISearchSetTireSize) => void;
  getMetaData: ({  }) => void;
}

interface IState {
  headerMenu: { name: string, value: any, id: number }[];
  activeHeaderMenu: number;
  toggelModal: boolean;
  tireData: ISearchGetTires;
  formData: { fields: { [key in string]: any }; required: string[] };
  validation: any;
  selectedSize: string[];
  showQuantity: boolean;
  selectedOptionF:string;
  selectedOptionS:string;
  selectedOptionT:string;
  selectedOptionFR:string;
  selectedOptionSR:string;
  selectedOptionTR:string;
  isMobile:boolean
}

class SizePage extends React.Component<IProps, IState> {

  state: IState = {
    headerMenu: [
      { name: `search.header.width`, value: '', id: 1 },
      { name: `search.header.aspect`, value: '', id: 2 },
      { name: `search.header.diameter`, value: '', id: 3 },
    ],
    activeHeaderMenu: 1,
    toggelModal: false,
    tireData: {
      f_size: 0,
      s_size: 0,
      t_size: 0,
      city_id: this.props.core.selectedCity.id,
      rear_size_tyre: 0,
      f_size_rear: 0,
      s_size_rear: 0,
      t_size_rear: 0,
      quantity: 0,
      quantity_rear: 0,
      promotions:{},
      promotions_rear:{},
      brand:''
    },
    formData: {
      fields: {
        quantity: '',
        quantity_rear: ''
      },
      required: ['quantity']
    },
    validation: {},
    selectedSize: ['front'],
    showQuantity: false,
    selectedOptionF:"",
    selectedOptionS:"",
    selectedOptionT:"",
    selectedOptionFR:"",
    selectedOptionSR:"",
    selectedOptionTR:"",
    isMobile: window.innerWidth < 768,
  }

  // Function to update state on window resize
  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 768 });
  };

  componentDidMount = () => {
    const { getTireSize, getTireSizeRear } = this.props;

    getTireSize({ type: 'f' });
    getTireSizeRear({ type: 'f' });
    window.onpopstate = () => {
      const { location } = this.props;
      if (this.state.selectedSize.length > 1) {
        this.resetSearch()
      }
    };

    this.props.getMetaData({
      slug: 'search_by_size'
    })

    window.addEventListener("resize", this.handleResize);

  }

  resetSearch = () => {
    const { tireData } = this.state;
    const { getTireSize, getTireSizeRear } = this.props;
    this.setState({
      selectedSize: ['front'],
      showQuantity: false,
      tireData: {
        f_size: 0,
        s_size: 0,
        t_size: 0,
        city_id: this.props.core.selectedCity.id,
        rear_size_tyre: 0,
        f_size_rear: 0,
        s_size_rear: 0,
        t_size_rear: 0,
        quantity: 0,
        quantity_rear: 0,
        promotions:{},
       promotions_rear:{},
       brand:''
      },
      headerMenu: [
        { name: `search.header.width`, value: tireData.f_size, id: 1 },
        { name: `search.header.aspect`, value: tireData.s_size, id: 2 },
        { name: `search.header.diameter`, value: tireData.t_size, id: 3 },
      ],
      activeHeaderMenu: 3
    })
    getTireSize({ type: 's', fsize: this.state.headerMenu[0].value, ssize: this.state.headerMenu[1].value })
    getTireSize({ type: 't', fsize: this.state.headerMenu[0].value, ssize: this.state.headerMenu[1].value })
    getTireSizeRear({ type: 's', fsize: this.state.headerMenu[0].value, ssize: this.state.headerMenu[1].value })
    getTireSizeRear({ type: 't', fsize: this.state.headerMenu[0].value, ssize: this.state.headerMenu[1].value })
  }

  // getTireSizeVal = (event:any, type: any, tireType:any) => {
  //   const selectedIndex = event.target.selectedIndex;
  //   const selectedOptionId = event.target.options[selectedIndex].value;
  getTireSizeVal = (e: any, data: any, type: any, tireType:any) => {
    const selectedOptionId = data.value; // Correct way to get selected value
    const updatedTireData = { ...this.state.tireData }; // Clone previous state
    if(selectedOptionId){
      if(tireType == 'front'){
        if(type ==='f'){
          this.setState({ selectedOptionF: selectedOptionId });
          updatedTireData.f_size = selectedOptionId;
        } else if(type ==='s'){
          this.setState({ selectedOptionS: selectedOptionId });
          updatedTireData.s_size = selectedOptionId;
        } else if(type ==='t'){
          this.setState({ selectedOptionT: selectedOptionId });
          updatedTireData.t_size = selectedOptionId;
        }
      } else if(tireType == 'rear'){
        if(type ==='f'){
          this.setState({ selectedOptionFR: selectedOptionId });
          updatedTireData.f_size_rear = selectedOptionId;
        } else if(type ==='s'){
          this.setState({ selectedOptionSR: selectedOptionId });
          updatedTireData.s_size_rear = selectedOptionId;
        } else if(type ==='t'){
          this.setState({ selectedOptionTR: selectedOptionId });
          updatedTireData.t_size_rear = selectedOptionId;
        }
      }
      // Update state
      this.setState({ tireData: updatedTireData });
      this.onSelect({ id:selectedOptionId, type: type, name: tireType }, true);
    }
  };

  // render2Item = () => {
  //   const { search, location } = this.props;
  //   const { activeHeaderMenu, headerMenu } = this.state;

  //   const DATA: { [key in number]: typeTireSize } = {
  //     1: 'f',
  //     2: 's',
  //     3: 't',
  //   }
  //   const type = DATA[activeHeaderMenu]
  //   return (
  //     <div className="col-lg-12">
  //       <ul className="year-filter">
  //         {
  //           search.tireSize[type].map((row: any, index: number) => {
  //             return <Years
  //               items={row} key={index}
  //               onClick={this.onSelect}
  //               selectedYear={0}
  //               selectedModel={0}
  //               headerMenu={headerMenu}
  //               activeHeaderMenu={activeHeaderMenu}
  //             />
  //           })
  //         }
  //       </ul>
  //     </div>
  //   )
  // }

  getOptionsValue = (search_val: any[], tireType: string, type: string) => {
    return search_val.map((row: any, index: number) => ({
      key: `${tireType}_${type}_${row.id || index}`, // Fully unique key
      value: row.id || row,
      text: row.id ? Filter.translate(row.model, row.model_arabic) : row
    }));
  };


  renderItem = () => {
    const { search, location } = this.props;
    const { activeHeaderMenu, headerMenu } = this.state;
    const DATA: { [key in number]: typeTireSize } = {
      1: 'f',
      2: 's',
      3: 't',
    }
    const type = DATA[activeHeaderMenu]

    return (
      <>
        <div className="form-group">
          <label>{i18n.t(`search.header.width`)}</label>

          <Dropdown
          key={`dropdown-f-front-single`}
          placeholder={i18n.t(`search_select_width`)}
          className='form-control'
          fluid
          selection
          search={this.state.isMobile ? false : true}
          options={this.getOptionsValue(search.tireSize['f'], 'front', 'f')}
          value={this.state.selectedOptionF}
          onChange={(e, data) => this.getTireSizeVal(e, data, 'f','front')}
        />

        </div>

        <div className="form-group">
          <label>{i18n.t(`search.header.aspect`)}</label>

          <Dropdown
          key={`dropdown-s-front-single`}
          placeholder={i18n.t(`search_select_aspect_ratio`)}
          className='form-control'
          fluid
          selection
          search={this.state.isMobile ? false : true}
          options={this.getOptionsValue(search.tireSize['s'], 'front', 's')}
          value={this.state.selectedOptionS}
          onChange={(e, data) => this.getTireSizeVal(e, data, 's','front')}
        />

        </div>
        <div className="form-group">
          <label>{i18n.t(`search.header.diameter`)}</label>

          <Dropdown
          key={`dropdown-t-front-single`}
          placeholder={i18n.t(`search_select_diameter`)}
          className='form-control'
          fluid
          selection
          search={this.state.isMobile ? false : true}
          options={this.getOptionsValue(search.tireSize['t'], 'front', 't')}
          value={this.state.selectedOptionT}
          onChange={(e, data) => this.getTireSizeVal(e, data, 't','front')}
        />

        </div>

        </>
      )

  }


  renderItemRear = () => {
    const { search, location } = this.props;
    const { activeHeaderMenu, headerMenu, tireData } = this.state;
    const DATA: { [key in number]: typeTireSize } = {
      1: 'f',
      2: 's',
      3: 't',
    }
    const type = DATA[activeHeaderMenu]
// console.log('ttttttt111',search);

    return (
      <>
      <div className="groupcolrow">
      <h2 className="rear-tire-size">{i18n.t(`cart.element.front_tire_size`)}</h2>
      <div className="row">
        <div className="col-4">
            <div className="form-group">
              <label>{i18n.t(`search.header.width`)}</label>

              <Dropdown
              key={`dropdown-f-front-double`}
              placeholder={i18n.t(`search.header.width`)}
              className='form-control'
              fluid
              selection
              search={this.state.isMobile ? false : true}
              options={this.getOptionsValue(search.tireSize['f'], 'front','f')}
              value={this.state.selectedOptionF}
              onChange={(e, data) => this.getTireSizeVal(e, data, 'f','front')}
              />

            </div>
            </div>
            <div className="col-4">
            <div className="form-group">
              <label>{i18n.t(`search.header.aspect`)}</label>

              <Dropdown
              key={`dropdown-s-front-double`}
              placeholder={i18n.t(`search_select_ratio`)}
              className='form-control'
              fluid
              selection
              search={this.state.isMobile ? false : true}
              options={this.getOptionsValue(search.tireSize['s'], 'front','s')}
              value={this.state.selectedOptionS}
              onChange={(e, data) => this.getTireSizeVal(e, data, 's','front')}
              />
</div>
            </div>
            <div className="col-4">
            <div className="form-group">
              <label>{i18n.t(`search.header.diameter`)}</label>

              <Dropdown
              key={`dropdown-t-front-double`}
              placeholder={i18n.t(`search.header.diameter`)}
              className='form-control'
              fluid
              selection
              search={this.state.isMobile ? false : true}
              options={this.getOptionsValue(search.tireSize['t'], 'front','t')}
              value={this.state.selectedOptionT}
              onChange={(e, data) => this.getTireSizeVal(e, data, 't','front')}
              />
</div>
            </div>
        </div>
        </div>
        <div className="groupcolrow">
        <h2 className="rear-tire-size">{i18n.t(`cart.element.rear_tire_size`)}</h2>
      <div className="row"> <div className="col-4">
        <div className="form-group">
          <label>{i18n.t(`search.header.width`)}</label>

          <Dropdown
          key={`dropdown-f-rear-double`}
          placeholder={i18n.t(`search.header.width`)}
          className='form-control'
          fluid
          selection
          search={this.state.isMobile ? false : true}
          options={this.getOptionsValue(search.tireSizeRear['f'], 'rear','f')}
          value={this.state.selectedOptionFR}
          onChange={(e, data) => this.getTireSizeVal(e, data, 'f','rear')}
          />
</div>
        </div>
        <div className="col-4">
        <div className="form-group">
          <label>{i18n.t(`search.header.aspect`)}</label>

          <Dropdown
          key={`dropdown-s-rear-double`}
          placeholder={i18n.t(`search_select_ratio`)}
          className='form-control'
          fluid
          selection
          search={this.state.isMobile ? false : true}
          options={this.getOptionsValue(search.tireSizeRear['s'], 'rear','s')}
          value={this.state.selectedOptionSR}
          onChange={(e, data) => this.getTireSizeVal(e, data, 's','rear')}
          />
</div>
        </div>
        <div className="col-4">
        <div className="form-group">
          <label>{i18n.t(`search.header.diameter`)}</label>

          <Dropdown
          key={`dropdown-t-rear-double`}
          placeholder={i18n.t(`search.header.diameter`)}
          className='form-control'
          fluid
          selection
          search={this.state.isMobile ? false : true}
          options={this.getOptionsValue(search.tireSizeRear['t'], 'rear','t')}
          value={this.state.selectedOptionTR}
          onChange={(e, data) => this.getTireSizeVal(e, data, 't','rear')}
          /></div>
</div>
        </div>
      </div>  

        </>
      )

  }


  onProceed = () => {
    const { headerMenu, formData, tireData, selectedSize } = this.state;
    const { getTires, history, core, location } = this.props;
    const param = {
      ...selectedSize.length === 1 ? {
        f_size: headerMenu[0].value,
        s_size: headerMenu[1].value,
        t_size: headerMenu[2].value,
      } : tireData,
      city_id: core.selectedCity.id,
      rear_size_tyre: selectedSize.length === 1 ? 0 : 1,
      f_size_rear: selectedSize.length === 1 ? '' : headerMenu[0].value,
      s_size_rear: selectedSize.length === 1 ? '' : headerMenu[1].value,
      t_size_rear: selectedSize.length === 1 ? '' : headerMenu[2].value,
      quantity: 1,
      quantity_rear: 0,
    }
    const validate = validation.isValidate(formData)
    this.setState({ validation: validate })
    // console.log('formData', formData);
// console.log('yyyyyyyyyyy=======>',formData);
    if (!Object.keys(validate).length) {
      // getTires({...param, ...formData.fields, ...selectedSize.length === 1 && { quantity_rear: formData.fields.quantity } });

// Remove unwanted keys

param.quantity = formData.fields.quantity;
param.quantity_rear = formData.fields.quantity_rear;
const filteredParams = Object.fromEntries(
  Object.entries(param).filter(([key]) => !["promotions", "promotions_rear"].includes(key))
);
// Convert to query string
const queryString = new URLSearchParams(filteredParams).toString();

      history.push(
        PATH.SEARCH_TIRES_LIST+'?'+queryString,
        {
          ...param,
          ...formData.fields,
          ...location.state && location.state.bannerData &&
          {
            offer_id: location.state.bannerData.offer_id,
            coupon_check: location.state.bannerData.offer_id ? 1 : 0,
          }
        }
      )
    }

  }

  onSelectRear = () => {
    const { headerMenu, tireData, formData, selectedSize } = this.state;
    const { history } = this.props;
    // const param = {
    //   f_size: headerMenu[0].value,
    //   s_size: headerMenu[1].value,
    //   t_size: headerMenu[2].value,
    //   f_size_rear: headerMenu[0].value,
    //   s_size_rear: headerMenu[1].value,
    //   t_size_rear: headerMenu[2].value,
    // }

    const param = {
      f_size: headerMenu[0].value,
      s_size: headerMenu[1].value,
      t_size: headerMenu[2].value,
      f_size_rear: 0,
      s_size_rear: 0,
      t_size_rear: 0,
    }

    this.setState({
      tireData: { ...tireData, ...param },
      activeHeaderMenu: 1,
      headerMenu: [
        { name: `search.header.width`, value: '', id: 1 },
        { name: `search.header.aspect`, value: '', id: 2 },
        { name: `search.header.diameter`, value: '', id: 3 },
      ],
      formData: {
        ...formData,
        fields: { quantity: '', quantity_rear: '' },
        required: ['quantity', 'quantity_rear']
      },
      showQuantity: false,
      selectedSize: [...selectedSize, 'rear']
    })
    // this.onToggel()
    // history.push(PATH.SEARCH_BY_SIZE)
    // this.setState({ selectedOptionF: "",  selectedOptionS:"", selectedOptionT:""});
  }

  onSelect = ({ id, type, name }: { id: any, type: string, name: string }, isClickMenu: boolean = false) => {
    const { getTireSize,getTireSizeRear } = this.props;
    const { activeHeaderMenu, headerMenu, tireData, selectedSize } = this.state
    const header = headerMenu;
    let activeHeaderMenu_var = activeHeaderMenu;
    if(type){
      if(type ==='f'){
        activeHeaderMenu_var = 1;
      } else if(type ==='s'){
        activeHeaderMenu_var = 2;
      } else if(type ==='t'){
        activeHeaderMenu_var = 3;
      }
    }
    header.splice(activeHeaderMenu_var - 1, 1, { ...header[activeHeaderMenu_var - 1], value: id })
    // console.log('govinddevjijijijiijijiij====>',header);
    this.setState({
      activeHeaderMenu: activeHeaderMenu_var === 3 ? 3 : activeHeaderMenu_var + 1,
      headerMenu: header
    }, () => {
      const DATA: { [key in number]: typeTireSize } = {
        1: 'f',
        2: 's',
        3: 't',
      }

      if (activeHeaderMenu_var <= 2) {
        if(name==='front'){
          getTireSize({ type: DATA[activeHeaderMenu_var + 1], fsize: this.state.headerMenu[0].value, ssize: this.state.headerMenu[1].value })
        } else {
          getTireSizeRear({ type: DATA[activeHeaderMenu_var + 1], fsize: this.state.headerMenu[0].value, ssize: this.state.headerMenu[1].value })
        }
      } else {
        // if (tireData.f_size) {
        //   // this.getTire() 
        //   this.setState({ selectedSize: [...selectedSize, 'rear'], showQuantity: true })
        // }
        if (!isClickMenu) {
          this.onToggel();
        }
      }
    })
  }


  // onSelect = ({ id, type, name }: { id: any, type: string, name: string }, isClickMenu: boolean = false) => {
  //   const { getTireSize } = this.props;
  //   const { activeHeaderMenu, headerMenu, tireData, selectedSize } = this.state
  //   const header = headerMenu;
  //   console.log('header========>0000', type);
  //   header.splice(activeHeaderMenu - 1, 1, { ...header[activeHeaderMenu - 1], value: id })
  //   console.log('header========>11111', header);
  //   console.log('header========>22222', activeHeaderMenu);

  //   this.setState({
  //     activeHeaderMenu: activeHeaderMenu === 3 ? 3 : activeHeaderMenu + 1,
  //     headerMenu: header
  //   }, () => {
  //     const DATA: { [key in number]: typeTireSize } = {
  //       1: 'f',
  //       2: 's',
  //       3: 't',
  //     }

  //     if (activeHeaderMenu <= 2) {
  //       getTireSize({ type: DATA[activeHeaderMenu + 1], fsize: this.state.headerMenu[0].value, ssize: this.state.headerMenu[1].value })
  //     } else {
  //       if (tireData.f_size) {
  //         // this.getTire() 
  //         this.setState({ selectedSize: [...selectedSize, 'rear'], showQuantity: true })
  //       }
  //       if (!isClickMenu) {
  //         this.onToggel();
  //       }
  //     }
  //   })
  // }

  onToggel = () => {
    const { toggelModal } = this.state;
    this.setState({ toggelModal: !toggelModal })
  }

  goBack = () => {
    this.props.history.goBack()
  }

  handleChange = (e: any, field: string) => {
    var arabics = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
    //var arabics2 = ['۰', '١', '٢', '', '', '', '', '', '', '', ''];
    var arabics2 = ['٠', '١', '٢', '٣', '٤', '٥', '٥', '٧', '٨', '٩'];
    var engs = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    let value = e.target.value;

    console.log(value);
    let index = arabics.indexOf(value);
    if (index != -1) {
      value = engs[index];
    }
    console.log(index, value);

    let index2 = arabics2.indexOf(value);
    if (index2 != -1) {
      value = engs[index2];
    }
    console.log(index2, value);

    const { formData } = this.state;
    value = value.replace(/^0+/, '')
    if (value.length > 1) {
      value = value.slice(1, 2);
    }

    if (!parseInt(value))
      value = '';

    this.setState({ formData: { ...formData, fields: { ...formData.fields, [field]: value } } })
  }

  renderInput = () => {
    const { formData, selectedSize } = this.state
    const quantityInputArr = selectedSize.length > 1 ? ['quantity', 'quantity_rear'] : ['quantity']
    return quantityInputArr.map((raw: string, index: number) => {
      return (
        <Input
          type={'text'}

          // @ts-ignore-start
          value={formData.fields[`${raw}`]}
          // @ts-ignore-end
          name="quantity"
          onChange={(e: any) => this.handleChange(e, raw)}
          placeholder={i18n.t(raw === 'quantity' ? selectedSize.length > 1 ? 'search.quantity.input.placholder.front' : 'search.quantity.input.placholder' : 'search.quantity.input.placholder.rear')}
          error={validation.renderError(raw, this.state.validation)}
          key={index}
          containerStyle={{ width: selectedSize.length > 1 ? '49%' : '100%' }}
          maxLength={1}
        />
      )
    })
  }

  onProceedSame = () => {
    const { formData } = this.state;
    this.setState({ showQuantity: true, toggelModal:true, formData: { ...formData, required: ['quantity'] } })
  }

  onClickMenu = (id: number) => {
    // console.log('id', id);
    const { headerMenu } = this.state;
    const { setTireSize } = this.props
    // this.onSelect({ id, type: '', name: '' }, true)
    this.setState({ activeHeaderMenu: id })
    if (id !== 3) {
      this.setState({
        headerMenu: [
          { name: `search.header.width`, value: headerMenu[0].value, id: 1 },
          { name: `search.header.aspect`, value: id === 2 ? headerMenu[1].value : '', id: 2 },
          { name: `search.header.diameter`, value: '', id: 3 },
        ],
      })
      setTireSize({ type: 't', items: [] })

    }
  }

  render() {
    const { headerMenu, activeHeaderMenu, toggelModal, tireData, selectedSize, showQuantity } = this.state;
    const {core, search} = this.props;
    // console.log('headerMenu', headerMenu);
    // console.log('rammmmmmmmmmmmmmmmmm===>111', headerMenu);
    // console.log('rammmmmmmmmmmmmmmmmm===>222', activeHeaderMenu);
    // console.log('rammmmmmmmmmmmmmmmmm===>333', selectedSize);
    // console.log('rammmmmmmmmmmmmmmmmm===>444', tireData);
    // console.log('rammmmmmmmmmmmmmmmmm===>555', this.state);

    return (
      <>

        <Helmet>
          <title>{core.metaData ? Filter.translate(core.metaData.title_en, core.metaData.title_arebic) : ""}</title>
          <meta name="description" content={core.metaData ? Filter.translate(core.metaData.meta_keyword_en, core.metaData.meta_keyword_ar) : ""} />
          <meta name="keywords" content={core.metaData ? Filter.translate(core.metaData.meta_description_en, core.metaData.meta_description_ar) : ""} />
        </Helmet>

        <div className="toolbar-enabled toolbar-marvelapp">
          <Header
            type={'type'}
            menu={headerMenu}
            active={activeHeaderMenu}
            title={i18n.t(`search.header.size.title`)}
            goBack={this.goBack}
            showRear={selectedSize.length > 1}
            tireData={tireData}
            onClickMenu={this.onClickMenu}
          />
          {/* <section className={'brands-estb'}>
            <div className="container">
              <div className="row pb-2 pb-sm-0 pb-md-3 brands">
                {this.render2Item()}
              </div>
            </div>
          </section> */}


          <section className="brands-marvelapp bgwhite tyrebrandratio">
    <div className="container">
      <div className="tyrerowdata">
        <div className="col-comman col-left">
          <div className="choose-tyre-row">
            <div className="tyrecolbox">
              <div className="widthtyre">
                <img src={images.tyreWidthImage} alt="" />
                <div className="squarecol width">
                  <span>{i18n.t(`search.header.width`)}</span>
                </div>
              </div>
              <div className="ratiorim">
                <img src={images.tyreFullImage} alt="" />
                <svg viewBox="0 0 197 197" width="197" height="197" className="textcurve">
                  <path id="curve" d="M30,120 A68,68 0 0,1 167,120" fill="transparent" stroke="none" />
                  <text font-size="16" font-weight="500" text-anchor="middle" fill="black">
                    <textPath href="#curve" startOffset="50%">
                      <tspan>{i18n.t(`search_dummy_width_size`)}</tspan>
                      <tspan>/</tspan>
                      <tspan>{i18n.t(`search_dummy_ratio_size`)}</tspan>
                      <tspan> {i18n.t(`search_dummy_diameter_size`)}</tspan>
                    </textPath>
                  </text>
                </svg>
    
                <div className="squarecol diameter">
                  <span>{i18n.t(`search.header.diameter`)}</span>
                </div>
                <div className="squarecol ratio">
                  <span>{i18n.t(`search_select_ratio`)}</span>
                </div>
              </div>
            </div>
    
          </div>
          <div className="widthratiopt">
            <div className="tyredtlscol">
              <span className="sizecol">{i18n.t(`search_dummy_width_size`)}</span>
              <span className="textcol">{i18n.t(`search.header.width`)}</span>
            </div>
            <div className="tyredtlscol">
              <span className="sizecol">{i18n.t(`search_dummy_ratio_size`)}</span>
              <span className="textcol">{i18n.t(`search_select_ratio`)}</span>
            </div>
            <div className="tyredtlscol">
              <span className="sizecol">{i18n.t(`search_dummy_diameter_size`)}</span>
              <span className="textcol">{i18n.t(`search.header.diameter`)}</span>
            </div>
          </div>
        </div>
        <div className="col-comman col-right">
          <div className="sizeboxpanel">
            <div className="sizeboxinner">
            
            <h2>{i18n.t(`get_right_tire_your_car`)}</h2>

                {selectedSize.length > 1 ? this.renderItemRear() : this.renderItem()}
              
              {/* {this.renderItem()} */}
              {/* {this.renderItemRear()} */}

              <div className="form-group">
                 <button className="btn findtyre-btn" onClick={this.onProceedSame}>{i18n.t(`search_find_your_tires`)}</button>
              </div>
              {selectedSize.length <= 1 && (
                  <a href={`javascript:void(0);`} className="textlink" onClick={this.onSelectRear}> {i18n.t('FRONT_REAR_TITLE')}  </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


        </div>
        <Modal
          onToggel={this.onToggel}
          show={toggelModal}
        >

          <div className="modal-header title-modal">
            <h4 className="modal-title">
              {
                showQuantity ? i18n.t(`search.size.modal.title.diffrent_size`) : i18n.t(`search.size.modal.title.same_size`)
              }
            </h4>
          </div>
          {
            showQuantity ? (
              <>
                <div className="modal-body quantity sizeQuantity">
                  {this.renderInput()}
                </div>
                <div className="modal-footer proceed">
                  <button type="button" className="footer-btn" onClick={this.onProceed}>{i18n.t(`search.size.modal.button.proceed`)}<img src={images.rightIcon} /></button>
                </div>
              </>
            ) : (
                <>

                  {/* <div className="modal-footer proceed">
                    <button type="button" className="footer-btn" onClick={this.onProceedSame}>{i18n.t('Yes_Proceed')}<img src={images.rightIcon} /></button>
                  </div> */}

                  {/* <a href={`javascript:void(0);`} className='selectSizeLink' onClick={this.onSelectRear}>{i18n.t('FRONT_REAR_TITLE')}</a> */}
                </>
              )
          }
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state: IRootState) => ({
  search: state.search,
  core: state.core
});

const mapDispatchToProps = {
  getBrand: searcActions.getBrand,
  getYear: searcActions.getYear,
  getModel: searcActions.getModel,
  getSize: searcActions.getSize,
  getTires: searcActions.getTires,
  getTireSize: searcActions.getTireSize,
  getTireSizeRear: searcActions.getTireSizeRear,
  setTireSize: searcActions.setTireSize,
  getMetaData: coreActions.getMetaData
}



export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(SizePage)))  