import edit from '../assets/edit.png';
import logo from '../assets/logo.png';
import cart from '../assets/cart.png';
import notification from '../assets/notification.png';
import user from '../assets/user.png';
import englishFlag from '../assets/english-flag.png';
import arFlag from '../assets/ar_flag.png';
import arrowDrop from '../assets/arrow-drop.png';
import slider from '../assets/slider-1.png';
import bannerTire from '../assets/banner-tire.png';
import byVehicle from '../assets/by-vehicle.png';
import bySize from '../assets/by-size.png';
import arrow from '../assets/arrow.png';
import icon1 from '../assets/icon-1.png';
import icon2 from '../assets/icon-2.png';
import icon3 from '../assets/icon-3.png';
import icon4 from '../assets/icon-4.png';
import tire2 from '../assets/tire-2.png';
import user1 from '../assets/user-1.png';
import user2 from '../assets/user-2.png';
import user3 from '../assets/user-3.png';
import quote from '../assets/quote.png';
import googlePlay from '../assets/google-play.png';
import apple from '../assets/apple.png';
import blog from '../assets/blog-1.png';
import facebook from '../assets/facebook.png';
import twitter from '../assets/twitter.png';
import insta from '../assets/insta.png';
import brand from '../assets/brands/brand-1.png';
import mobileApp from '../assets/mobile-app.png';
import innerTireMark from '../assets/inner-tire-mark.png';
import aboutImg1 from '../assets/about-img1.png';
import aboutImg2 from '../assets/about-img2.png';
import check from '../assets/check.png';
import contactIcon1 from '../assets/contact-icon-1.png';
import contactIcon2 from '../assets/contact-icon-2.png';
import contactIcon3 from '../assets/contact-icon-3.png';
import leftArrow from '../assets/left-arrow.png';
import right from '../assets/right.png';
import rightIcon from '../assets/right-icon.png';
import thumbTire2 from '../assets/thumb-tire-2.png';
import fuelIcon from '../assets/fuel_icon.png';
import clouldIcon from '../assets/clould_icon.png';
import leftArrowBlack from '../assets/left-arrow_black.png';
import yellowLogo from '../assets/yellow_logo.png';
import place from '../assets/place.png';
import loader from '../assets/loader.gif';
import emptyTire from '../assets/Empty-tire-list.png';
import carOrderModal from '../assets/car_order-modal.png';
import notificationBell from '../assets/notification_bell.png';
import notificationNodata from '../assets/notification_nodata.png';
import checkLogin from '../assets/check_login.png';
import dealer from '../assets/dealer.png';
import whare_house from '../assets/whare_house.png';
import navIcon from '../assets/nav-icon.svg';
import manageOrder from '../assets/manage_order.png';
import manageTyre from '../assets/manage-tyre.png';
import inventoryIcon from '../assets/inventory-icon.png';
import search from '../assets/search.png';
import calendarIcon from '../assets/calendar-icon.png';
import exportIcon from '../assets/export.png';
import pulse from '../assets/pulse.png';
import deleteIcon from '../assets/delete.png';
import pencil from '../assets/pencil.png';
import shop from '../assets/shop.png';
import bulider from '../assets/bulider.jpg';
import markerActive from '../assets/pin-active.png';
import orangepin from '../assets/orangepin.png';
import blackpin from '../assets/blackpin.png';

import carSelected from '../assets/car-selected.png';
import carUnselected from '../assets/car-unselected.png';
import tyreSelected from '../assets/tyre-selected.png';
import tyreUnselected from '../assets/tyre-unselected.png';
import soldImage from '../assets/01.svg';
import inStockImage from '../assets/02.svg';
import lowStockImage from '../assets/03.svg';
import tamaraImage from '../assets/tamara.jpg';
import tamaraImageEn from '../assets/tamara_en.png';
import tabbyImage from '../assets/tabby.png';
import cashPayImage from '../assets/pay_at.png';
import currencyImage from '../assets/currency.svg';
import currencyWhiteImage from '../assets/currency_white.svg';
import currencyGreenImage from '../assets/currency_green.svg';
import couponTickImage from '../assets/check-check.svg';
import tyreFullImage from '../assets/images/tyrefull.png';
import tyreWidthImage from '../assets/images/tyrewidth.png';

const images = {
  soldImage,
  inStockImage,
  lowStockImage,
  edit,
  logo,
  cart,
  notification,
  emptyTire,
  user,
  englishFlag,
  arFlag,
  arrowDrop,
  slider,
  bannerTire,
  byVehicle,
  bySize,
  arrow,
  icon1,
  icon2,
  icon3,
  icon4,
  tire2,
  user1,
  user2,
  user3,
  quote,
  googlePlay,
  apple,
  blog,
  facebook,
  twitter,
  insta,
  brand,
  mobileApp,
  innerTireMark,
  aboutImg1,
  aboutImg2,
  check,
  contactIcon1,
  contactIcon2,
  contactIcon3,
  leftArrow,
  right,
  rightIcon,
  thumbTire2,
  fuelIcon,
  clouldIcon,
  leftArrowBlack,
  yellowLogo,
  place,
  loader,
  carOrderModal,
  notificationBell,
  notificationNodata,
  checkLogin,
  dealer,
  whare_house,
  navIcon,
  manageOrder,
  manageTyre,
  inventoryIcon,
  search,
  calendarIcon,
  exportIcon,
  pulse,
  deleteIcon,
  pencil,
  shop,
  bulider,
  markerActive,
  orangepin,
  blackpin,
  carSelected,
  carUnselected,
  tyreSelected,
  tyreUnselected,
  tamaraImage,
  tamaraImageEn,
  tabbyImage,
  cashPayImage,
  currencyImage,
  currencyWhiteImage,
  couponTickImage,
  currencyGreenImage,
  tyreFullImage,
  tyreWidthImage

}

export {
  images
}