import React, { createRef } from 'react';
import { images } from '../../../../constants/Images';
import { Link } from 'react-router-dom';
import { ISearchGetTires } from '../../../../models/ISearchState';
import { withTranslation } from 'react-i18next';
import { i18n } from '../../../../services';

interface IProps {
  type: string;
  // selectedBrandName: string;
  // selectedModel: string;
  // selectedYear: number;
  menu: { name: string, value: any, id: number }[]
  active: number;
  title : string;
  goBack: () => void
  showRear: boolean;
  tireData: ISearchGetTires
  onClickMenu: (id: number) => void;
}

interface IState {

}



class Header extends React.Component<IProps, IState> {
    static defaultProps = {
      showRear: false,
      tireData: {}
    }

    renderItem = () => {
      const { menu, type, active, showRear, tireData } = this.props;
      // console.log('menu', menu);
      const dataKeys: Record<number, any> = {
        1: tireData.f_size,
        2: tireData.s_size,
        3: tireData.t_size,
      }
      return menu.map((row, index) => {
        return (
          <>
          <li className={`${(active === row.id) && 'active'}`} key={index}>
            {
              (showRear && index == 0) && (
                <span className="front-ttl">{i18n.t('FRONT_TEXT')}:</span>
              )
            }          
            <a href="javascript:void(0)" onClick={() => this.props.onClickMenu && !showRear ? this.props.onClickMenu(row.id) : {}}>{i18n.t(row.name)}</a>
            {/* <span className="step">{showRear && tireData ? dataKeys[row.id]  : row.value}</span> */}
            <span className="step">{Object.keys(tireData).length > 0 ? dataKeys[row.id] ? dataKeys[row.id] : '' : row.value}</span>
          </li>
          </>
        )
      })
    }

    renderItemRear = () => {
      const { menu, type, active, tireData } = this.props;
      // console.log('active', active);

      const dataKeys: Record<number, any> = {
        1: tireData.f_size_rear,
        2: tireData.s_size_rear,
        3: tireData.t_size_rear,
      }
      
      return menu.map((row, index) => {
        return (
          <>
          <li className={`${active === row.id && 'active'}`} key={index}>
          {
              index == 0 && (
                <span className="front-ttl">{i18n.t('REAR_TEXT')}:</span>
              )
            }
          
            <a href="javascript:void(0)" onClick={() => this.props.onClickMenu ? this.props.onClickMenu(row.id) : {}}>{i18n.t(row.name)}</a>
            {/* <span className="step">{row.value}</span> */}
            <span className="step">{Object.keys(tireData).length > 0 ? dataKeys[row.id] ? dataKeys[row.id] : '' : row.value}</span>
          </li>
          </>
        )
      })
    }
    
    render() {
      const { title, goBack, showRear, tireData={} } = this.props
      
      return (
        <header className="navbar d-block  navbar-sticky navbar-expand-lg filter-header">
        <div className="container-fluid">
                      <div className="navbar-by">
                    <h1>{title}</h1>
                  </div>
                  <div className="navbar-left">
                    <a href="javascript:void(0);" onClick={goBack}><img src={images.leftArrow} /></a>
                    <h1>{title}</h1>
                  </div>
                
        <nav>
      <div className="navbar-toolbar d-flex align-items-center order-lg-3">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse">
      <span className="navbar-toggler-icon"></span>
              </button>

      <div className="collapse navbar-collapse" id="#navbarCollapse">
        <div className="navbar-right ">
                <ul className="menu left-to-right">
                  {this.renderItem()}  
                </ul>
                {
                  showRear && (
                    <ul className="menu left-to-right rearMenu">
                    {this.renderItemRear()}
                  </ul>
                  )
                }
               
              </div>
                  </div>
            </div>

          </nav>
      </div>
    </header>
      )
    }
}

export default withTranslation()<any>(Header)