
import { AnyAction } from 'redux';
import { ICoreNotificationType, ICoreNotificationTemplateType, ICoreNotification, ICorePages, ICorePagesSet, ICoreContact, ICoreCities, ICoreUpdateCity, IFaq, IMetaFaq, IMetaData } from '../models/ICoreState';
/*
 * Define action name constants here
 */
export enum ActionTypes {
  SET_LOADING = 'ui/SET_LOADING',
  ADD_ERROR = 'ui/ADD_ERROR',
  CLEAR_ERROR = 'ui/CLEAR_ERROR',
  ADD_NOTIFICATION = 'ui/ADD_NOTIFICATION',
  REMOVE_NOTIFICATION = 'ui/REMOVE_NOTIFICATION',
  CLEAR_ALL_NOTIFICATON = 'settings/CLEAR_ALL_NOTIFICATON',
  GET_PAGE = 'core/GET_PAGE',
  SET_PAGE = 'core/SET_PAGE',
  CONTACT_US = 'core/CONTACT_US',
  GET_CITIES = 'core/GET_CITIES',
  SET_CITIES = 'core/SET_CITIES',
  UPDATE_CITY = 'core/UPDATE_CITY',
  SET_UPDATED_CITY = 'core/SET_UPDATED_CITY',
  SUBSCRIBE_NEWSLETTER = 'core/SUBSCRIBE_NEWSLETTER',
  SUBSCRIBE_NEWSLETTER_SUCCESS = 'core/SUBSCRIBE_NEWSLETTER_SUCCESS',
  FORCE_TO_LOGIN = 'core/FORCE_TO_LOGIN',
  GET_FAQ = 'core/GET_FAQ',
  SET_FAQ = 'core/SET_FAQ',
  SET_METAFAQ = 'core/SET_METAFAQ',
  GET_METADATA = 'core/GET_METADATA',
  SET_METADATA = 'core/SET_METADATA',
}

/*
 * Define return types of actions
 */

export interface IActionCoreGetFaq extends AnyAction {
  type: ActionTypes.GET_FAQ;
}

export interface IActionCoreSetFaq extends AnyAction {
  type: ActionTypes.SET_FAQ;
  payload: IFaq[]
}

export interface IActionCoreSetMetaFaq extends AnyAction {
  type: ActionTypes.SET_METAFAQ;
  payload: IMetaFaq
}

export interface IActionCoreGetMetaData extends AnyAction {
  type: ActionTypes.GET_METADATA;
  payload: ICorePages;
}
export interface IActionCoreSetMetaData extends AnyAction {
  type: ActionTypes.SET_METADATA;
  payload: IMetaData
}

export interface IActionCoreForceToLogin extends AnyAction {
  type: ActionTypes.FORCE_TO_LOGIN;
  payload: boolean
}

export interface IActionCoreSubscribeNewsLetterSuccess extends AnyAction {
  type: ActionTypes.SUBSCRIBE_NEWSLETTER_SUCCESS;
  payload: boolean
}

export interface IActionCoreSubscribeNewsLetter extends AnyAction {
  type: ActionTypes.SUBSCRIBE_NEWSLETTER;
  payload: {email: string}
}

export interface IActionUpdateCity extends AnyAction {
  type: ActionTypes.UPDATE_CITY;
  payload: ICoreCities
}

export interface IActionSetUpdatedCity extends AnyAction {
  type: ActionTypes.SET_UPDATED_CITY;
  payload: ICoreCities
}

export interface IActionGetCities extends AnyAction {
  type: ActionTypes.GET_CITIES;
}

export interface IActionSetCities extends AnyAction {
  type: ActionTypes.SET_CITIES;
  payload: ICoreCities[];
}

 export interface IActionContatcUs extends AnyAction {
  type: ActionTypes.CONTACT_US;
  payload: ICoreContact;
}


export interface IActionGetPage extends AnyAction {
  type: ActionTypes.GET_PAGE;
  payload: ICorePages;
}

export interface IActionSetPage extends AnyAction {
  type: ActionTypes.SET_PAGE;
  payload: ICorePagesSet;
}

export interface IActionClearAllNotification extends AnyAction {
  type: ActionTypes.CLEAR_ALL_NOTIFICATON;
}

export interface IActionAddNotification extends AnyAction {
  type: ActionTypes.ADD_NOTIFICATION;
  payload: ICoreNotification;
}

export interface IActionSetLoading extends AnyAction {
  type: ActionTypes.SET_LOADING;
  payload: { loading: boolean };
}

export interface IActionAddError extends AnyAction {
  type: ActionTypes.ADD_ERROR;
  payload: Error;
}

export interface IActionRemoveNotification extends AnyAction {
  type: ActionTypes.REMOVE_NOTIFICATION;
  payload: string | undefined;
}



/**
 * get page
 *
 * @export
 * @param ICorePages
 * @returns {IActionGetPage}
 */
export function getPage (payload: ICorePages): IActionGetPage {
  return {
    payload,
    type: ActionTypes.GET_PAGE,
  };
}

/**
 * set page
 *
 * @export
 * @param ICorePagesSet
 * @returns {IActionSetPage}
 */
export function setPage (payload: ICorePagesSet): IActionSetPage {
  return {
    payload,
    type: ActionTypes.SET_PAGE,
  };
}


/**
 * Set global loading status of web
 *
 * @export
 * @param {boolean} loading
 * @returns {IActionSetLoading}
 */
export function setLoading (loading: boolean): IActionSetLoading {
  return {
    payload: {
      loading,
    },
    type: ActionTypes.SET_LOADING,
  };
}

/**
 * Save error in core state
 *
 * @export
 * @param {(Error | string)} error
 * @returns {IActionAddError}
 */
export function addError (error: Error | string): IActionAddError {
  return {
    payload: error instanceof Error ? error : new Error(error),
    type: ActionTypes.ADD_ERROR,
  };
}

/**
 * Add notification to list.
 * If list has this notification, nothing do.
 *
 * @export
 * @param {string} notification
 * @returns {IActionAddNotification}
 */
export function addNotification (
  message: string,
  type: ICoreNotificationType = 'error',
  templatetype: ICoreNotificationTemplateType = 'notification',
): IActionAddNotification {
  return {
    payload: { message, type, templatetype },
    type: ActionTypes.ADD_NOTIFICATION,
  };
}

/**
 * Remove notification from list.
 * If not set, remove last notification.
 *
 * @export
 * @param {string} notification
 * @returns {IActionRemoveNotification}
 */
export function removeNotification (notification?: string): IActionRemoveNotification {
  return {
    payload: notification,
    type: ActionTypes.REMOVE_NOTIFICATION,
  };
}

/**
 *
 * @export
 * @param {ICoreContact} payload
 * @returns {IActionContatcUs}
 */
export function contactUs (payload: ICoreContact): IActionContatcUs {
  return {
    payload,
    type: ActionTypes.CONTACT_US,
  };
}
/**
 * @returns IActionGetCities
 */
export function getCities (): IActionGetCities {
  return {
    type: ActionTypes.GET_CITIES,
  };
}

/**
 * @param  {ICoreCities[]} payload
 * @returns IActionSetCities
 */
export function setCities (payload: ICoreCities[]): IActionSetCities {
  return {
    payload,
    type: ActionTypes.SET_CITIES,
  };
}

/**
 * @param  {ICoreUpdateCity} payload
 * @returns IActionUpdateCity
 */
export function updateCity (payload: ICoreCities): IActionUpdateCity {
  return {
    payload,
    type: ActionTypes.UPDATE_CITY,
  };
}

/**
 * @param  {ICoreCities} payload
 * @returns IActionSetUpdatedCity
 */
export function setUpdatedCity (payload: ICoreCities): IActionSetUpdatedCity {
  return {
    payload,
    type: ActionTypes.SET_UPDATED_CITY,
  };
}

/**
 * @param  {{email:string}} payload
 * @returns IActionCoreSubscribeNewsLetter
 */
export function subscribeNewsLetter (payload: {email: string}): IActionCoreSubscribeNewsLetter {
  return {
    payload,
    type: ActionTypes.SUBSCRIBE_NEWSLETTER,
  };
}

/**
 * @param  {boolean} payload
 * @returns IActionCoreSubscribeNewsLetterSuccess
 */
export function subscribeNewsLetterSuccess (payload: boolean): IActionCoreSubscribeNewsLetterSuccess {
  return {
    payload,
    type: ActionTypes.SUBSCRIBE_NEWSLETTER_SUCCESS,
  };
}

export function forceToLogin (payload: boolean): IActionCoreForceToLogin {
  return {
    payload,
    type: ActionTypes.FORCE_TO_LOGIN,
  };
}

export function getFaq (): IActionCoreGetFaq {
  return {
    type: ActionTypes.GET_FAQ,
  };
}

export function setFaq (data: IFaq[]): IActionCoreSetFaq {
  return {
    type: ActionTypes.SET_FAQ,
    payload: data
  };
}

export function getMetaData (payload: ICorePages): IActionCoreGetMetaData {
  return {
    type: ActionTypes.GET_METADATA,
    payload
  };
}

export function setMetaData (data: IMetaData): IActionCoreSetMetaData {
  return {
    type: ActionTypes.SET_METADATA,
    payload: data
  };
}

export function setMetaFaq (data: IMetaFaq): IActionCoreSetMetaFaq {
  return {
    type: ActionTypes.SET_METAFAQ,
    payload: data
  };
}

export type Action =
  IActionSetLoading
  | IActionAddError
  | IActionRemoveNotification
  | IActionClearAllNotification
  | IActionAddNotification
  | IActionGetPage
  | IActionSetPage
  | IActionContatcUs
  | IActionGetCities
  | IActionSetCities
  | IActionUpdateCity
  | IActionSetUpdatedCity
  | IActionCoreSubscribeNewsLetter
  | IActionCoreSubscribeNewsLetterSuccess
  | IActionCoreForceToLogin
  | IActionCoreGetFaq
  | IActionCoreSetFaq
  | IActionCoreSetMetaFaq
  | IActionCoreGetMetaData
  | IActionCoreSetMetaData
